/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import Gs from '../theme/globalStyles'
import styled from 'styled-components'
import { Link, NavLink ,useLocation } from 'react-router-dom'
import Media from '../theme/media-breackpoint'
import LogoImg from '../assets/images/logo.png'
import Hright from '../assets/images/hright.png'
import giftBlack from '../assets/images/giftB.png';


function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Header(props) {

  let query = useQuery();
  const goToAPP = () =>{
    const ref = query.get('ref');
    if(ref !== undefined)
      window.location.href = `https://app.pdo.finance/#/list?ref=${ref}`;
    else
      window.location.href = 'https://app.pdo.finance/#/list';
  }

  return (
    <>
      <TopBar>
        <img src={giftBlack} />
        <p>Refer & win up to a <span>$1000</span> reward</p>
      </TopBar>
      <HeaderOuter>
        <Logo as={Link} to='/giveaway'><img src={LogoImg} alt='Logo'/></Logo>
        <HeaderR href='/airdrop-distribution'>
          <img src={Hright} alt='Logo' />
        </HeaderR>
      </HeaderOuter>
    </>

  )

}
const FlexDiv = styled.div`
  display: flex; align-items: center; justify-content:center; flex-wrap:wrap;
`;
const Logo = styled.a ` display: flex; align-items: center;
  img {filter: drop-shadow(0 0 0.45rem #91dc27);}
  /* img {filter: drop-shadow(0 0 15px rgba(145,220,39,1));} */
  &:after {content: ""; border-left: 5px solid transparent; border-right: 5px solid transparent; border-top: 5px solid #fff; margin-left: 52px;}
  ${Media.sm}{
    img {width: 200px;}
    &:after {margin-left: 30px;}
  }
`
const HeaderOuter = styled(FlexDiv)`
  justify-content:space-between; padding: 25px 30px; width:100%; position: absolute; top: 40px; left: 0; right: 0;
  ${Media.sm}{
    padding:20px 15px; top: 30px;
  }
`;

const HeaderR = styled.a`
  
`;
const TopBar = styled.div `
  position: relative; top: 0; left: 0; right: 0; height: 40px; background: var(--primeGreen); display: flex; justify-content: center; align-items: center; z-index: 2;
  p {color: #000; font-weight: 700; font-size: 14px; margin: 2px 0 0;
    span {color: #fff; text-shadow: 0 0 10px rgba(0,0,0,0.8);}
  }
  img {filter: brightness(0); height: 25px; margin-right: 16px;}
  &:after {content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0; background: linear-gradient(90deg, var(--primeGreen), #538e00, var(--primeGreen)); z-index: -1; transition: all 0.3s ease-in-out; opacity: 0;}
  &:hover {
    &:after {opacity: 1;}
  }
  ${Media.sm}{
    height: 30px;
    p {font-size: 12px;}
    img {height: 20px; margin-right: 10px;}
  }
`
export default Header;
