/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component, useEffect, useState } from 'react';
import styled from 'styled-components';
import Gs from '../theme/globalStyles';
import { Link, NavLink, useLocation } from 'react-router-dom';
import Media from '../theme/media-breackpoint';
import Header from './../component/header' 
import Footer from './../component/Footer' 
import AOS from 'aos';
import 'aos/dist/aos.css';
import smartIco from '../assets/images/smart.png';
import icon1 from '../assets/images/icon1.png'
import icon2 from '../assets/images/icon2.png'
import icon3 from '../assets/images/icon3.png'
import icon4 from '../assets/images/icon4.png'
import Rocket from '../assets/images/rocket.png'
import Nft01 from '../assets/images/nft01.png'
import Nft02 from '../assets/images/nft02.png'
import Nft03 from '../assets/images/nft03.png'
import Nft04 from '../assets/images/nft04.jpg'
import Arrow from '../assets/images/arrow-enter.png'
import Ico1 from './../assets/images/sb-ICO-01.png'
import dropico from '../assets/images/dropico.png'


function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function AirdropConnected(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    let query = useQuery();
    AOS.init({
        once: true,
        duration: 1500,
    });
    const [ToggleState, setToggleState] = useState(1);
    const toggleTab = (index) => {
        setToggleState(index);
    };
    const getActiveClass = (index, className) =>
        ToggleState === index ? className : '';
    return (
        <>
            <Header />
            <Container>
                <PageContainer>
                    <h1>Project distributes airdrops to holders and traders for <span>FREE</span></h1>

                    <TopMenu>
                        <a onClick={() => toggleTab(1)} className={getActiveClass(1, 'active')}>Airdrop tokens</a>
                        <a onClick={() => toggleTab(2)} className={getActiveClass(2, 'active')}>Airdrop NFTs</a>
                    </TopMenu>

                    {getActiveClass(1,
                        <TabContent>
                            <FormRow>
                                <div className='formCol'>
                                    <label>Place your token contract</label>
                                    <div className='textBox'>
                                        <input type='text' className='inputBx' placeholder='' value='0x5f2caa99fc378248ac02c...' />
                                        <span className='txtSub'>[TOKEN]</span>
                                    </div>
                                </div>
                                <div className='formCol'>
                                    <label>Airdrop to <sup><i className="fas fa-question-circle"></i></sup></label>
                                    <div className='textBox'>
                                        <RadioBtn>
                                            <input type='radio' name='radio01' /> <span>Holders</span>
                                        </RadioBtn>
                                        <RadioBtn>
                                            <input type='radio' name='radio01' /> <span>Traders</span>
                                        </RadioBtn>
                                    </div>
                                </div>
                            </FormRow>
                            <FormRow>
                                <div className='formCol'>
                                    <label>Start airdrop date <sup><i className="fas fa-question-circle"></i></sup></label>
                                    <div className='textBox'>
                                        <input type='text' className='inputBx' placeholder='' value='November 30, 2020' />
                                        <span className='IconSub'><i className="fas fa-calendar-alt"></i></span>
                                    </div>
                                </div>
                                <div className='formCol'>
                                    <label>End airdrop date <sup><i className="fas fa-question-circle"></i></sup></label>
                                    <div className='textBox'>
                                        <input type='text' className='inputBx' placeholder='' value='November 30, 2020' />
                                        <span className='IconSub'><i className="fas fa-calendar-alt"></i></span>
                                    </div>
                                </div>
                            </FormRow>
                            <FormRow>
                                <div className='formCol'>
                                    <label>Airdrop supply <sup><i className="fas fa-question-circle"></i></sup></label>
                                    <div className='textBox'>
                                        <input type='text' className='inputBx' placeholder='' />
                                        <div className='floatingInfo'>Balance: 10,000,000 DOT</div>
                                    </div>
                                </div>
                                <div className='formCol'>
                                    <label>Estimated airdrops</label>
                                    <div className='textBox'>
                                        <p className='estimate'>
                                            <b>Per block</b> 50
                                        </p>
                                        <p className='estimate'>
                                            <b>Per day</b> 1,000
                                        </p>
                                    </div>
                                </div>
                            </FormRow>
                            <Divider />
                            <FormRow>
                                <div className='formCol'>
                                    <label>Airdrop limitations</label>
                                    <div className='textBox'>
                                        <RadioBtn>
                                            <input type='radio' name='radio01' /> <span>No</span>
                                        </RadioBtn>
                                        <RadioBtn>
                                            <input type='radio' name='radio01' /> <span>Yes</span>
                                        </RadioBtn>
                                    </div>
                                </div>
                                <div className='formCol'>
                                    <label>Maximum per claimer</label>
                                    <div className='textBox'>
                                        <input type='text' className='inputBx' placeholder='' />
                                        <span className='txtSub'>TOKEN</span>
                                    </div>
                                </div>
                            </FormRow>
                            <FormRow>
                                <div className='formCol'>
                                    <label>Target claimers <sup><i className="fas fa-question-circle"></i></sup></label>
                                    <div className='textBox'>
                                        <RadioBtn>
                                            <input type='radio' name='radio01' /> <span>All claimers</span>
                                        </RadioBtn>
                                        <RadioBtn>
                                            <input type='radio' name='radio01' /> <span>Selected claimers</span>
                                        </RadioBtn>
                                    </div>
                                </div>
                                <div className='formCol'>
                                    <label>Select claimers groups</label>
                                    <div className='textBox'>
                                        <DropdownBtn>
                                            <span><img src={smartIco} alt='smartIco' /> SMART | 12,563 holders</span>
                                            <i className="fas fa-caret-down"></i>
                                        </DropdownBtn>
                                    </div>
                                </div>
                            </FormRow>
                            <FormRow>
                                <div className='formCol alignSelf-right'>
                                    <div className='textBox'>
                                        <SelectList>
                                            <li>
                                                <div className=''><img src={icon1} /></div>
                                                <p>DEGEN | 10,000 holders
                                                    <b>0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc09</b>
                                                </p>
                                                <a className="fas fa-times close"></a>
                                            </li>
                                            <li>
                                                <div className=''><img src={icon1} /></div>
                                                <p>DEGEN | 10,000 holders
                                                    <b>0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc09</b>
                                                </p>
                                                <a className="fas fa-times close"></a>
                                            </li>
                                            <li>
                                                <div className=''><img src={icon1} /></div>
                                                <p>DEGEN | 10,000 holders
                                                    <b>0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc09</b>
                                                </p>
                                                <a className="fas fa-times close"></a>
                                            </li>
                                            <div className='info'>* First-come, first-served distribution</div>
                                        </SelectList>
                                    </div>
                                </div>
                            </FormRow>
                            <Divider />
                            <FormRow>
                                <div className='formCol'>
                                    <label>Dumper Shield  Protection <sup><i className="fas fa-question-circle"></i></sup></label>
                                    <div className='textBox'>
                                        <RadioBtn>
                                            <input type='radio' name='radio01' /> <span>No</span>
                                        </RadioBtn>
                                        <RadioBtn>
                                            <input type='radio' name='radio01' /> <span>Yes</span>
                                        </RadioBtn>
                                    </div>
                                </div>
                                <div className='formCol'>
                                    <label>Choose the dumper shield period <sup><i className="fas fa-question-circle"></i></sup></label>
                                    <div className='textBox'>
                                        <div className='infoRight'>
                                            Existing dumper shield been find for that token
                                            <b>April 26, 2023, 5:39pm OTC</b>
                                        </div>
                                    </div>
                                </div>
                            </FormRow>
                            <FormRow>
                                <div className='formCol alignSelf-right'>
                                    <label>Choose positions <sup><i className="fas fa-question-circle"></i></sup></label>
                                    <div className='textBox'>
                                        <DropdownBtn>
                                            <span>1st</span>
                                            <i className="fas fa-caret-down"></i>
                                        </DropdownBtn>
                                    </div>
                                </div>
                            </FormRow>
                            <FormRow>
                                <div className='formCol'>
                                    <label>KYC/AML required? <sup><i className="fas fa-question-circle"></i></sup></label>
                                    <div className='textBox'>
                                        <RadioBtn>
                                            <input type='radio' name='radio01' /> <span>No</span>
                                        </RadioBtn>
                                        <RadioBtn>
                                            <input type='radio' name='radio01' /> <span>Yes</span>
                                        </RadioBtn>
                                    </div>
                                </div>
                                <div className='formCol alignSelf-right'>
                                    <label>KYC/AML soulbound
                                        smart contract <sup><i className="fas fa-question-circle"></i></sup></label>
                                    <div className='textBox'>
                                        <input type='text' className='inputBx' placeholder='' />
                                    </div>
                                </div>
                            </FormRow>
                            <Button className='lg'>LAUNCH YOUR AIRDROP <img src={Rocket} /></Button>
                        </TabContent>
                    )}

                    {getActiveClass(2,
                        <TabContent>
                            <FormRow>
                                <div className='formCol'>
                                    <label>NFT status <sup><i className="fas fa-question-circle"></i></sup></label>
                                    <div className='textBox'>
                                        <RadioBtn>
                                            <input type='radio' name='radio01' /> <span>New NFTs</span>
                                        </RadioBtn>
                                        <RadioBtn>
                                            <input type='radio' name='radio01' /> <span>Existing NFTs</span>
                                        </RadioBtn>
                                    </div>
                                </div>
                                <div className='formCol'>
                                    <label>Airdrop supply</label>
                                    <div className='textBox'>
                                        <input type='text' className='inputBx' placeholder='' value='0x5f2caa99fc378248ac02c...' />
                                        <div className='floatingInfo'>Balance: 10,000,000 DOT</div>
                                    </div>
                                </div>
                            </FormRow>
                            <FormRow>
                                <input type='file' className='hiddenFile' id='DropFile' name='' />
                                <DragDrop htmlFor='DropFile'>
                                    <img src={dropico} alt='dropico' />
                                    <h4>Drag & drop NFTs</h4>
                                    <p>or browse media on your device</p>
                                    <p>Max size: 50MB <br />
                                        JPG, PNG, GIF, SVG, MP4</p>
                                </DragDrop>
                            </FormRow>
                            <FormRow>
                                <div className='formCol lg'>
                                    <label>Select NFTs</label>
                                    <div className='textBox'>
                                        <CheckBox>
                                            <input type='checkbox' name='checkbox01' /> <span>Select all</span>
                                        </CheckBox>
                                        <CheckBox>
                                            <input type='checkbox' name='checkbox01' /> <span>Select collections</span>
                                        </CheckBox>
                                    </div>
                                    <div className='textBox'>
                                        <DropdownBtn>
                                            <span><img src={smartIco} alt='smartIco' /> SMART | 12,563 holders</span>
                                            <i className="fas fa-caret-down"></i>
                                        </DropdownBtn>
                                        <DropDownList>
                                            <div className='search'>
                                                <input type='text' className='' placeholder='Search claimers by name or contract ' />
                                                <i className="fas fa-caret-up"></i>
                                            </div>
                                            <table>
                                                <tr>
                                                    <th>Token</th>
                                                    <th>Holders</th>
                                                    <th></th>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b><img src={icon1} alt='smartIco' /> SMART</b>
                                                    </td>
                                                    <td>12,563 holders</td>
                                                    <td>
                                                        <CheckBox>
                                                            <input type='checkbox' name='check1' /> <span></span>
                                                        </CheckBox>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b><img src={icon2} alt='smartIco' /> SMART</b>
                                                    </td>
                                                    <td>12,563 holders</td>
                                                    <td>
                                                        <CheckBox>
                                                            <input type='checkbox' name='check1' /> <span></span>
                                                        </CheckBox>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b><img src={icon3} alt='smartIco' /> SMART</b>
                                                    </td>
                                                    <td>12,563 holders</td>
                                                    <td>
                                                        <CheckBox>
                                                            <input type='checkbox' name='check1' /> <span></span>
                                                        </CheckBox>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b><img src={icon4} alt='smartIco' /> SMART</b>
                                                    </td>
                                                    <td>12,563 holders</td>
                                                    <td>
                                                        <CheckBox>
                                                            <input type='checkbox' name='check1' /> <span></span>
                                                        </CheckBox>
                                                    </td>
                                                </tr>
                                            </table>
                                        </DropDownList>
                                    </div>
                                </div>
                            </FormRow>
                            <FormRow>
                                <NftBoxList>
                                    <li><h5><img src={Nft01} /> 4431.jpg</h5>
                                        <b>202 kb <i className="fas fa-times"></i></b>
                                    </li>
                                    <li><h5><img src={Nft01} /> 4431.jpg</h5>
                                        <b>202 kb <i className="fas fa-times"></i></b>
                                    </li>
                                    <li><h5><img src={Nft01} /> 4431.jpg</h5>
                                        <b>202 kb <i className="fas fa-times"></i></b>
                                    </li>
                                    <li><h5><img src={Nft02} /> 4431.jpg</h5>
                                        <b>202 kb <i className="fas fa-times"></i></b>
                                    </li>
                                    <li><h5><img src={Nft02} /> 4431.jpg</h5>
                                        <b>202 kb <i className="fas fa-times"></i></b>
                                    </li>
                                    <li><h5><img src={Nft02} /> 4431.jpg</h5>
                                        <b>202 kb <i className="fas fa-times"></i></b>
                                    </li>
                                    <li><h5><img src={Nft03} /> 4431.jpg</h5>
                                        <b>202 kb <i className="fas fa-times"></i></b>
                                    </li>
                                    <li><h5><img src={Nft03} /> 4431.jpg</h5>
                                        <b>202 kb <i className="fas fa-times"></i></b>
                                    </li>
                                    <li><h5><img src={Nft03} /> 4431.jpg</h5>
                                        <b>202 kb <i className="fas fa-times"></i></b>
                                    </li>
                                    <li><h5><img src={Nft04} /> 4431.jpg</h5>
                                        <b>202 kb <i className="fas fa-times"></i></b>
                                    </li>
                                    <li><h5><img src={Nft04} /> 4431.jpg</h5>
                                        <b>202 kb <i className="fas fa-times"></i></b>
                                    </li>
                                    <li><h5><img src={Nft04} /> 4431.jpg</h5>
                                        <b>202 kb <i className="fas fa-times"></i></b>
                                    </li>
                                </NftBoxList>
                            </FormRow>
                            <FormRow>
                                <Button className='lg v2'>Connect your wallet to display and select NFTs for airdrops</Button>
                            </FormRow>
                            <FormRow>
                                <div className='formCol'>
                                    <label>Start airdrop date <sup><i className="fas fa-question-circle"></i></sup></label>
                                    <div className='textBox'>
                                        <input type='text' className='inputBx' placeholder='' value='November 30, 2020' />
                                        <span className='IconSub'><i className="fas fa-calendar-alt"></i></span>
                                    </div>
                                </div>
                                <div className='formCol'>
                                    <label>End airdrop date <sup><i className="fas fa-question-circle"></i></sup></label>
                                    <div className='textBox'>
                                        <input type='text' className='inputBx' placeholder='' value='November 30, 2020' />
                                        <span className='IconSub'><i className="fas fa-calendar-alt"></i></span>
                                    </div>
                                </div>
                            </FormRow>




                            <Divider />
                            <FormRow>
                                <div className='formCol'>
                                    <label>Airdrop limitations</label>
                                    <div className='textBox'>
                                        <RadioBtn>
                                            <input type='radio' name='radio01' /> <span>No</span>
                                        </RadioBtn>
                                        <RadioBtn>
                                            <input type='radio' name='radio01' /> <span>Yes</span>
                                        </RadioBtn>
                                    </div>
                                </div>
                                <div className='formCol'>
                                    <label>Maximum per claimer</label>
                                    <div className='textBox'>
                                        <input type='text' className='inputBx' placeholder='' />
                                        <span className='txtSub'>NFTs</span>
                                    </div>
                                </div>
                            </FormRow>
                            <FormRow>
                                <div className='formCol'>
                                    <label>Target claimers <sup><i className="fas fa-question-circle"></i></sup></label>
                                    <div className='textBox'>
                                        <RadioBtn>
                                            <input type='radio' name='radio01' /> <span>All claimers</span>
                                        </RadioBtn>
                                        <RadioBtn>
                                            <input type='radio' name='radio01' /> <span>Selected claimers</span>
                                        </RadioBtn>
                                    </div>
                                </div>
                                <div className='formCol'>
                                    <label>Select claimers groups</label>
                                    <div className='textBox'>
                                        <DropdownBtn>
                                            <span><img src={smartIco} alt='smartIco' /> SMART | 12,563 holders</span>
                                            <i className="fas fa-caret-down"></i>
                                        </DropdownBtn>
                                        <DropDownList>
                                            <div className='search'>
                                                <input type='text' className='' placeholder='Search claimers by name or contract ' />
                                                <i className="fas fa-caret-up"></i>
                                            </div>
                                            <table>
                                                <tr>
                                                    <th>Token</th>
                                                    <th>Holders</th>
                                                    <th></th>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b><img src={icon1} alt='smartIco' /> SMART</b>
                                                    </td>
                                                    <td>12,563 holders</td>
                                                    <td>
                                                        <CheckBox>
                                                            <input type='radio' name='check1' /> <span></span>
                                                        </CheckBox>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b><img src={icon2} alt='smartIco' /> SMART</b>
                                                    </td>
                                                    <td>12,563 holders</td>
                                                    <td>
                                                        <CheckBox>
                                                            <input type='radio' name='check1' /> <span></span>
                                                        </CheckBox>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b><img src={icon3} alt='smartIco' /> SMART</b>
                                                    </td>
                                                    <td>12,563 holders</td>
                                                    <td>
                                                        <CheckBox>
                                                            <input type='radio' name='check1' /> <span></span>
                                                        </CheckBox>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b><img src={icon4} alt='smartIco' /> SMART</b>
                                                    </td>
                                                    <td>12,563 holders</td>
                                                    <td>
                                                        <CheckBox>
                                                            <input type='radio' name='check1' /> <span></span>
                                                        </CheckBox>
                                                    </td>
                                                </tr>
                                            </table>
                                        </DropDownList>
                                    </div>
                                </div>
                            </FormRow>
                            <FormRow>
                                <div className='formCol alignSelf-right'>
                                    <div className='textBox'>
                                        <SelectList>
                                            <li>
                                                <div className=''><img src={icon1} /></div>
                                                <p>DEGEN | 10,000 holders
                                                    <b>0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc09</b>
                                                </p>
                                                <a className="fas fa-times close"></a>
                                            </li>
                                            <li>
                                                <div className=''><img src={icon1} /></div>
                                                <p>DEGEN | 10,000 holders
                                                    <b>0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc09</b>
                                                </p>
                                                <a className="fas fa-times close"></a>
                                            </li>
                                            <li>
                                                <div className=''><img src={icon1} /></div>
                                                <p>DEGEN | 10,000 holders
                                                    <b>0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc09</b>
                                                </p>
                                                <a className="fas fa-times close"></a>
                                            </li>
                                            <div className='info'>* First-come, first-served distribution</div>
                                        </SelectList>
                                    </div>
                                </div>
                            </FormRow>
                            <Divider />
                            <FormRow>
                                <div className='formCol'>
                                    <label>Dumper Shield  Protection <sup><i className="fas fa-question-circle"></i></sup></label>
                                    <div className='textBox'>
                                        <RadioBtn>
                                            <input type='radio' name='radio01' /> <span>No</span>
                                        </RadioBtn>
                                        <RadioBtn>
                                            <input type='radio' name='radio01' /> <span>Yes</span>
                                        </RadioBtn>
                                    </div>
                                </div>
                            </FormRow>
                            <FormRow>
                                <div className='formCol'>
                                    <label>KYC/AML required? <sup><i className="fas fa-question-circle"></i></sup></label>
                                    <div className='textBox'>
                                        <RadioBtn>
                                            <input type='radio' name='radio01' /> <span>No</span>
                                        </RadioBtn>
                                        <RadioBtn>
                                            <input type='radio' name='radio01' /> <span>Yes</span>
                                        </RadioBtn>
                                    </div>
                                </div>
                            </FormRow>
                            <Button className='lg'>LAUNCH YOUR AIRDROP <img src={Rocket} /></Button>
                            <Button className='lg v3'>CONNECT WALLET</Button>
                        </TabContent>
                    )}
                </PageContainer>
            </Container>
            <Footer />
        </>
    )
}


const PageContainer = styled.div`
    padding: 150px 0 0;  width: 100%;
    h1 {font-size: 36px; line-height: 1.4; margin: 0 0 38px 0; text-align: center;
        span {color: #91dc27; text-transform: uppercase;}
    }
    ${Media.md} {
        h1 {font-size: 25px;}
    }
`
const FlexDiv = styled.div`
    display: flex; align-items: center; justify-content:center; flex-wrap:wrap;
`;
const Container = styled(FlexDiv)`
    margin: 0px auto; width: 1360px; max-width: calc(100% - 40px);
    ${Media.lg} {width: 1100px;}
    ${Media.lg2} {width: 960px;}
`
const TopMenu = styled.div`
    padding: 48px 0px 0px; border-bottom: 2px solid #fff; display: flex; margin-bottom: 58px;
    a {color: #fff; padding: 2px 20px 32px; font-size: 24px; font-weight: bold; flex-grow: 1; text-align: center; position: relative; width: 100%; 
        &:last-child {border-right: 0 solid #000; box-shadow: 0 0 0 #3d3f49; }
        img {margin-right: 11px; filter: brightness(100); transition: all 0.3s ease-in-out 0s; height: 20px;}
        .badge {background-color: #91dc27; font-size: 12px; width: 27px; height: 27px; position: absolute; top: 0; border-radius: 100px; line-height: 27px; margin: -13px 0 0 6px; color: #0d0e13 !important;}
        &.activeRed {color: var(--red2); box-shadow: 0 2px 0 var(--red2);}
        &.active {color: #91dc27; box-shadow: 0 2px 0 #91dc27;
            img {filter: brightness(1);}
        }
        &:hover {color: #545453; img {filter: brightness(1);}}
    }
    ${Media.md} {
        padding-top: 20px;
        a {font-size: 20px; padding: 2px 10px 32px;}
    }
    ${Media.xs} {
        a {font-size: 18px; padding: 2px 10px 25px;}
    }
`
const TabContent = styled.div`
    
`
const FormRow = styled.div`
    display: flex; align-items: flex-start; gap: 70px; width: 100%; margin-bottom: 30px;
    .formCol {display: table; width: 100%; max-width: calc(50% - 35px);
        sup {top: -0.8em;}
        &.alignSelf-right {margin-left: auto;}
        & > label {display: table-cell; width: 270px; font-weight: 700; text-align: left; margin: 0 0 0 0; line-height: 1.6; min-height: 56px; vertical-align: middle;}
        .textBox {
            position: relative; width: 370px; min-height: 56px; display: flex; align-items: center;  flex-flow: wrap; margin-left: auto;
            .inputBx {width: 100%; height: 56px; border: 2px solid #000; background: #21232b; font-size: 16px; color: #fff; padding: 0 30px 0 13px; border-radius: 5px;}
            .txtSub {position: absolute; top: 0; bottom: 0; right: 0; background: #000; display: flex; align-items: center; justify-content: center; padding: 0 17px; font-weight: 700; border-radius: 0 5px 5px 0;}
            .IconSub {position: absolute; top: 0; bottom: 0; right: 0; display: flex; align-items: center; justify-content: center; padding: 0 17px; font-weight: 700; font-size: 16px;}
            .estimate {display: flex; width: 100%; font-size: 12px; color: #8e9195;
                b {width: 80px; text-align: left; font-weight: normal;}
            }
            .infoRight {
                font-size: 11px; color: #c32b2d; text-align: right; margin-left: auto;
                b {display: block; font-size: 16px; color: #8e9195; margin-top: 6px;}
            }
            .floatingInfo {position: absolute; bottom: -20px; right: 0; font-size: 11px; color: #8e9195;}
        }
        &.lg {width: 100%; max-width: 100%; display: flex;
            & > label {display: flex; align-items: center; white-space: nowrap;}
            .textBox {
            }
        }
    }
    .hiddenFile {position: absolute; opacity: 0;}
    ${Media.lg} {gap: 30px;
        .formCol {
            & > label {padding-right: 10px;}
        }
    }
    ${Media.lg2} {
        .formCol {
            flex-flow: column; display: flex; flex-grow: 1; max-width: 100%;
            & > label {min-height: auto; margin-bottom: 10px; width: 100%;}
            .textBox {margin: 0; width: 100%;
                .infoRight {text-align: left; margin-left: 0;}
            }
        }
    }
    ${Media.md2} {
        flex-flow: column;
    }
`
const RadioBtn = styled.label`
    position: relative; width: 50%;
    span {
        position: relative; display: flex; align-items: center;
        &:before {content: ''; display: inline-block; width: 22px; height: 22px; border-radius: 100px; border: 2px solid #000; background: #21232b; box-shadow: inset 0 0 0 5px #21232b; margin-right: 12px;}
    }
    input {position: absolute; opacity: 0; visibility: hidden;}
    input:checked + span:before {background: #91dc27;}
    
`
const CheckBox = styled.label`
    position: relative; width: 50%;
    span {
        position: relative; display: flex; align-items: center;
        &:before {content: ''; display: inline-block; width: 18px; height: 18px; border-radius: 2px; border: 2px solid #000; background: #21232b; box-shadow: inset 0 0 0 5px #21232b; margin-right: 12px; flex-shrink: 0;}
        &:after {content: "\f00c"; font-family: "Font Awesome 5 Free";  font-weight: 900; position: absolute; left: 6px; top: 0; font-size: 14px; color: #91dc27; opacity: 0;}
    }
    input {position: absolute; opacity: 0; visibility: hidden;}
    input:checked + span:after {opacity: 1; }
    
`
const Divider = styled.div`
    background: #3b3e4b; width: 100%; height: 1px; display: table; margin: 40px 0 40px;
`
const DropdownBtn = styled.div`
    width: 100%; height: 56px; border: 2px solid #000; background: #21232b; font-size: 16px; color: #fff; padding: 0 13px 0 13px; display: flex; align-items: center; justify-content: space-between; border-radius: 5px;
    span {display: flex; align-items: center; justify-content: space-between; 
        img {margin-right: 15px; width: 24px; height: 24px; object-fit: contain;}
    }
`
const DropDownList = styled.div`
    width: 100%; border: 2px solid #000; background: #21232b; font-size: 16px; color: #fff; display: flex; align-items: center; flex-flow: column; justify-content: space-between; position: absolute; top: 0; left: 0; right: 0; z-index: 1; padding: 0 18px 10px; /* display: none; */ border-radius: 5px;
    .search {padding: 11px 30px 11px 0;  width: 100%; position: relative;
        input {width: 100%; height: 40px; border: 2px solid #000; background: #21232b; font-size: 14px; color: #565a69; padding: 0 30px 0 13px; border-radius: 5px;}
        i {position: absolute; right: 2px; top: 50%; font-size: 16px; height: 20px; line-height: 20px; margin-top: -10px;}
    }
    table {width: 100%; font-size: 14px;
        th {text-align: left; padding: 7px 0; color: #a6a2b0;}
        td {padding: 6px 0;
            b {display: flex; align-items: center; font-weight: normal; color: #a6a2b0;
                img {width: 23px; height: 23px; object-fit: contain; margin-right: 15px;}
            }
            ${CheckBox} {
                span {width: 20px;}
            }
        }
    }
`
const SelectList = styled.ul`
    width: 100%; padding: 0; margin: -25px 0 0;
    li {display: flex; list-style: none; padding: 17px 16px; text-align: left; position: relative; border-bottom: 1px solid #3b3e4b;
        & > div {flex-shrink: 0; margin-right: 15px; img {width: 24px; height: 24px; object-fit: contain;}}
        p {font-size: 14px;
            b {display: block; font-size: 12px; font-weight: normal; color: #8e9195; margin-top: 7px;}
        }
        .close {position: absolute; right: 0; top: 17px; font-size: 14px; color: #8e9195;
            &:hover {color: #c32b2d;}
        }
    }
    .info {width: 100%; color: #c32b2d; font-size: 12px; padding: 12px 0;}
    ${Media.lg2} {
        margin-top: 0;
    }
    ${Media.sm} {
        li p {max-width: calc(100% - 26px);
            b {width: 100%; overflow: hidden; text-overflow: ellipsis;}
        }
    }
`
const Button = styled.a`
    width: 660px; height: 80px; display: table; margin: 0 auto; font-size: 24px; font-weight: 700; color: #91dc27; background: #0d0e13; box-shadow: 0 0 15px rgba(255,255,255,0.7); display: flex; align-items: center; justify-content: center; margin: 65px auto 65px; max-width: 100%; text-align: center; padding: 10px 10px; border-radius: 5px;
    img {margin-left: 33px;}
    &:hover {background: #91dc27; color: #fff;}
    &.v2 {font-size: 18px; color: #fff; border: 2px solid #000; box-shadow: 0 0 15px #323232; height: 70px;}
    &.v3 {color: #fff; }
    ${Media.sm} {
        font-size: 20px;
    }
    ${Media.uxs} {
        font-size: 18px; height: 60px;
        img {width: 25px;}
    }
`
const DragDrop = styled.label`
    position: relative; width: 432px; height: 300px; background: #21232b; text-align: center; align-items: center; justify-content: center; display: flex; flex-flow: column; border: 2px dashed #000; margin: 20px auto; 
    h4 {font-size: 18px; color: #949494; margin: 35px 0 15px;}
    p {font-size: 14px; color: #949494; line-height: 1.6; margin: 0 0 17px;
        &:last-child {margin-bottom: 0;}
    }
`
const NftBoxList = styled.ul`
    margin: 0 0 20px; padding: 0; display: grid; grid-template-columns: 1fr 1fr 1fr; width: 100%;
    li {
        list-style: none; border: 1px solid #000; background: #21232b; display: flex; align-items: center; padding: 10px 12px;; overflow: hidden; margin: 0 0 -1px -1px;
        h5 {margin: 0; /* display: flex; */ align-items: center; font-size: 14px; font-weight: 700; color: #feffff; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width: 200px;
            img {width: 40px; height: 40px; margin-right: 17px; vertical-align: middle;}
        }
        b {font-size: 12px; margin-left: auto; color: #949494; font-weight: normal;
            i, a {margin-left: 20px; color: #949494; cursor: pointer;
                &:hover {color: #c32b2d;}
            }
        }
        &:hover {background: #171920;}
    }
`

export default AirdropConnected;