/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import Gs from '../theme/globalStyles'
import styled from 'styled-components'
import { Link, NavLink ,useLocation } from 'react-router-dom'
import Media from '../theme/media-breackpoint'
import LogoImg from '../assets/images/airdropLogoY.png'
import btn01 from '../assets/images/chrome2.png';
import giftBlack from '../assets/images/giftB.png';


function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Header(props) {

  let query = useQuery();
  /* const goToAPP = () =>{
    const ref = query.get('ref');
    if(ref !== undefined)
      window.location.href = `https://app.pdo.finance/#/list?ref=${ref}`;
    else
      window.location.href = 'https://app.pdo.finance/#/list';
  } */

  return (
    <>
      <TopBar>
        <img src={giftBlack} />
        <p>Refer & win up to a <span>$1000</span> reward</p>
      </TopBar>
      <HeaderOuter>
        <Logo><img src={LogoImg} alt='Logo'/></Logo>
        <BtnTop onClick={props.openClick}>
          <img src={btn01} alt='chrome'/>
          <div className='btnTxt'>
              <b>Install Virtual Wallet</b>
              <i>Web store</i>
          </div>
        </BtnTop>
      </HeaderOuter>
    </>

  )

}
const FlexDiv = styled.div`
  display: flex; align-items: center; justify-content:center; flex-wrap:wrap;
`;
const Logo = styled.a ` display: flex; align-items: center;
  img {/* filter: drop-shadow(0 0 0.45rem #91dc27); */}
  /* img {filter: drop-shadow(0 0 15px rgba(145,220,39,1));} */
  &:after {content: ""; border-left: 5px solid transparent; border-right: 5px solid transparent; border-top: 5px solid #fff; margin-left: 52px;}
  ${Media.sm}{
    img {width: 200px;}
    &:after {margin-left: 30px;}
  }
`
const HeaderOuter = styled(FlexDiv)`
  justify-content:space-between; padding: 34px 40px; width:100%; position: absolute; top: 40px; left: 0; right: 0; flex-flow: row;
  ${Media.sm}{
    padding:20px 15px; top: 30px;
  }
`;

const BtnTop = styled.a`
  display: flex; align-items: center; font-weight: bold; background: none; color: #0d0e13; text-align: left; justify-content: center; padding: 14px 22px; box-shadow: 0 0 15px #9d9d9d; color: #ffff00; border-radius: 5px;
  img {width: 30px; height: 30px; flex-shrink: 0; margin-right: 18px; transition: all 0.5s ease-in-out;}
  b {display: block; font-size: 16px; color: #ffff00; transition: all 0.5s ease-in-out;}
  i {display: block; font-size: 11px; font-style: normal; color: #a6a2b0; transition: all 0.5s ease-in-out 0s; margin-top: 2px;}
  &:hover {box-shadow: 0 0 15px #ffff00;}
  ${Media.sm}{
    padding: 14px 15px; 
    img {margin-right: 12px;}
    b {font-size: 14px;}
  }
  ${Media.xs}{
    box-shadow: none; padding: 0;
    .btnTxt {display: none;}
    img {margin-right: 0;}
  }
`;

const TopBar = styled.div `
  position: absolute; top: 0; left: 0; right: 0; height: 40px; background: var(--primeYellow); display: flex; justify-content: center; align-items: center; z-index: 2;
  p {color: #000; font-weight: 700; font-size: 14px; margin: 2px 0 0;
    span {color: #fff; text-shadow: 0 0 10px rgba(0,0,0,0.8);}
  }
  img {filter: brightness(0); height: 25px; margin-right: 16px;}
  &:after {content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0; background: linear-gradient(90deg, var(--primeYellow), #adad00, var(--primeYellow)); z-index: -1; transition: all 0.3s ease-in-out; opacity: 0;}
  &:hover {
    &:after {opacity: 1;}
  }
  ${Media.sm}{
    height: 30px;
    p {font-size: 12px;}
    img {height: 20px; margin-right: 10px;}
  }
`
export default Header;
