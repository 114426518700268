/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component, useEffect, useState } from 'react';
import styled from 'styled-components';
import Gs from '../theme/globalStyles';
import { Link, NavLink, useLocation } from 'react-router-dom';
import Media from '../theme/media-breackpoint';
import Header from './../component/header' 
import Footer from './../component/Footer' 
import AOS from 'aos';
import 'aos/dist/aos.css';
import Bsright from '../assets/images/bs-right.png';
import rocket from '../assets/images/rocket.png';
import bannerBg from '../assets/images/bannerBg.png';
import img01 from '../assets/images/img01.png';
import img02 from '../assets/images/img02.png';
import img03 from '../assets/images/img03.png';
import img04 from '../assets/images/img04.png';
import img05 from '../assets/images/img05.png';
import img06 from '../assets/images/img06.png';
import img07 from '../assets/images/img07.png';
import Htw01 from '../assets/images/htw01.png';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Home(props) {

  let query = useQuery();

  AOS.init({
    once: true,
    duration: 1500,
  });

  const goToAPP = () => {
    const ref = query.get('ref');
    if(ref !== undefined)
      window.location.href = `https://app.pdo.finance/#/list?ref=${ref}`;
    else
      window.location.href = 'https://app.pdo.finance/#/list';
  }
  return (
    <>
      <Header />
      <BannerSec>
        <Container>
          <div className='Bs-left' data-aos="fade-left">
            <h1 className='wow fadeInUp' data-wow-delay='0.2s'>Project, distributes airdrops to holders and traders for <span>FREE</span></h1>
            <p>Boost your project's success by sharing your tokens or NFTs with over
10 Million+ verified wallet holders.</p>
            <BtnXl href='/airdrop-distribution-launch'>LAUNCH AIRDROP GIVEAWAY <img src={rocket} alt='rocket' /></BtnXl>
          </div>
          <div className='Bs-right' data-aos="fade-right">
            <img src={Bsright} />
          </div>
        </Container>
      </BannerSec>
      <OddEven>
        <ImgTxtSec>
          <Container>
            <div className='ImgSec' data-aos="fade-right">
              <img src={img01} />
            </div>
            <div className='TxtSec' data-aos="fade-left">
              <h3>Growing your community</h3>
              <p>Airdrops are an excellent marketing strategy to increase your project's visibility and attract more users. By distributing tokens directly to existing crypto holders, you can easily capture the attention of many. This method effectively spreads the word about your project and generates interest among people to explore and engage with it.</p>
            </div>
          </Container>
        </ImgTxtSec>
        <ImgTxtSec>
          <Container>
            <div className='ImgSec' data-aos="fade-right">
              <img src={img02} />
            </div>
            <div className='TxtSec' data-aos="fade-left">
              <h3>Targeting and encouraging<br/> specific holders and traders</h3>
              <p>Offering airdrops to particular users who own specific tokens can motivate them to participate in your project. This tactic is particularly beneficial for attracting holders and traders with an interest in certain areas or specific investment behaviors, such as those who invest in similar projects.</p>
            </div>
          </Container>
        </ImgTxtSec>
        <ImgTxtSec>
          <Container>
            <div className='ImgSec' data-aos="fade-right">
              <img src={img03} />
            </div>
            <div className='TxtSec' data-aos="fade-left">
              <h3>Democratizing your token</h3>
              <p>Airdrops facilitate the distribution of your tokens to a broader audience, ensuring that ownership is not concentrated among a few. This wider ownership contributes to making your token more stable and appealing for transactions, which is advantageous for attracting investors and traders.</p>
            </div>
          </Container>
        </ImgTxtSec>
        <ImgTxtSec>
          <Container>
            <div className='ImgSec' data-aos="fade-right">
              <img src={img04} />
            </div>
            <div className='TxtSec' data-aos="fade-left">
              <h3>Staking alternative</h3>
              <p>Performing airdrops block by block to self-custoday vault holders encourages your community to retain your token, thereby reducing the selling pressure and potentially increasing purchasing and holding interest. </p>
            </div>
          </Container>
        </ImgTxtSec>
        <ImgTxtSec>
          <Container>
            <div className='ImgSec' data-aos="fade-right">
              <img src={img05} />
            </div>
            <div className='TxtSec' data-aos="fade-left">
              <h3>Protect your airdrop from bad actors</h3>
              <p>Many bad actors use new wallets along with wash trading to pretend they are active users in order to gain as many airdrops as possible, and then they become massive dumpers. Our system is designed to prevent such abuse and ensure that only real and verified long-term holders will receive your airdrops, with no risk of double spending. This means that holders can't move their holdings between multiple vaults to receive more airdrops, since if funds are moved from one wallet to another, the first wallet will receive less airdrop accordingly.</p>
            </div>
          </Container>
        </ImgTxtSec>
        <ImgTxtSec>
          <Container>
            <div className='ImgSec' data-aos="fade-right">
              <img src={img06} />
            </div>
            <div className='TxtSec' data-aos="fade-left">
              <h3>Targeting big investors and holders</h3>
              <p>The airdrop will be distributed pro-rata based on how many tokens each claimer has. So, small holders who are new to the crypto space with a small amount of money will receive far fewer airdrops compared to those who invest heavily in projects.</p>
            </div>
          </Container>
        </ImgTxtSec>
        <ImgTxtSec>
          <Container>
            <div className='ImgSec' data-aos="fade-right">
              <img src={img07} />
            </div>
            <div className='TxtSec' data-aos="fade-left">
              <h3>Targeting long-term holders</h3>
              <p>The pro-rata airdrop distribution over a long period of time ensures that most of the airdrop will be given to holders who prove themselves by holding tokens for a long time.</p>
            </div>
          </Container>
        </ImgTxtSec>
      </OddEven>
      <HowItWorks>
        <Container>
          <h3>How it works</h3>
          <div className='pointerMain'>
            <div className='pm-img'>
              <img src={Htw01} />
            </div>
            <ul className='pm-text'>
              <li data-aos="fade-up">
                <h4><span>3</span> Manage your airdrop supply</h4>
                <p>Adjust your airdrop supply, adding more or withdrawing unclaimed tokens at any time back to your wallet.</p>
              </li>
              <li data-aos="fade-up">
                <h4><span>2</span> Launch airdrop engagement</h4>
                <p>Our system will notify your chosen claimers about your airdrops and invite them to claim it at any time, while they cover the claiming cost. This means there is no distribution gas cost for you! even if you airdrop an Ethereum-based token to millions of claimers. no only that even your distribution airdrop fee will be paid with your own tokens and will be distributed to $SMART token holders as airdrop as well, so you will gain another verified claimers group that will join your community.</p>
              </li>
              <li data-aos="fade-up">
                <h4><span>1</span> Initiate your airdrop campaign</h4>
                <p>Deposit the airdrop supply and select the groups of claimers you wish to distribute your airdrop to, easily by choosing the tokens those claimers are already holding. For example, you can airdrop your token to all ETH or SHIB holders. You may select unlimited claimer groups as you like, even if there are more than a million holders in total. There is no need to upload any wallet addresses for the claimers, our system will handle the matching for you. Choose a start and end date, and if necessary, specify any supply limitation per holder. Based on that period, your supply will be distributed block by block pro-rata to ensure that no holder receives a large amount of the airdrop instantly, which could create a risk of dumping to your token price.</p>
              </li>
            </ul>
          </div>
        </Container>
      </HowItWorks>
      <Launch>
        <Container>
          <BtnXl data-aos="fade-up" as={Link} to='/airdrop-distribution-launch'>LAUNCH AIRDROP GIVEAWAY <img src={rocket} alt='rocket' /></BtnXl>
        </Container>
      </Launch>
      <Footer />
    </>
  )
}

const FlexDiv = styled.div`
  display: flex; align-items: center; justify-content:center; flex-wrap:wrap;
`;
const Container = styled(FlexDiv) `
  margin: 0px auto; width: 1360px; max-width: calc(100% - 40px);
  ${Media.lg} {width: 1100px;}
  ${Media.lg2} {width: 960px;}
`
const BannerSec = styled.div `
  width: 100%; min-height: 100vh; padding: 215px 0; background: url(${bannerBg}) no-repeat top right;
  .Bs-left {width: 50%;
    h1 {font-size: 48px; margin: 0 0 28px;;
      span {color: #91dc27;}
    }
    p {font-size: 18px; color: #a6a2b0; line-height: 1.6; margin-bottom: 38px; line-height: 1.7;}
  } 
  .Bs-right {width: 50%; text-align: right;}
  ${Media.lg} {
    .Bs-right {padding-left: 40px;}
    .Bs-left {
      h1 {font-size: 40px;}
    }
  }
  ${Media.md2} {
    text-align: center; padding: 150px 0 100px; background: none;
    .Bs-left {width: 100%; order: 2;}
    .Bs-right {width: 100%; text-align: center; order: 1; padding: 0 0 20px;
      img {width: 400px; max-width: 100%;}
    }
    a {margin-left: auto; margin-right: auto;}
  }
  ${Media.xs} {
    min-height: inherit;
    .Bs-left {
      h1 {font-size: 22px; margin: 0 0 14px;}
      p {margin: 0 0 20px; font-size: 16px;}
    }
    .Bs-right {
      img {width: 300px;}
    }
  }
  ${Media.uxs} {
    .Bs-right {
      img {width: 200px;}
    }
  }
  
`
const BtnXl = styled.a `
  width: 662px; height: 85px; border: 2px solid #7e7e81; display: flex; justify-content: center; align-items: center; box-shadow: 0 0 10px #7e7e81; font-size: 24px; color: #91dc27; font-weight: 700; max-width: 100%; padding: 0 20px; text-align: center; border-radius: 5px;
  &:hover {box-shadow: 0 0 10px #91dc27; border: 2px solid #91dc27;}
  img {margin-left: 25px;}
  ${Media.xs} {
    font-size: 20px; height: auto; padding: 15px 15px; text-align: left;
    img {margin-left: 20px; width: 30px;} 
  }
  ${Media.uxs} {
    font-size: 14px;
  }
`
const ImgTxtSec = styled.div `
  width: 100%; margin-bottom: 120px;
  .ImgSec {width: 50%; text-align: center;}
  .TxtSec {width: 50%; 
    h3 {margin: 0 0 35px; font-size: 36px; color: #fff;}
    p {font-size: 18px; color: #a6a2b0; line-height: 1.7; margin: 0;}
  }
  ${Media.lg} {
    .ImgSec {
      img {width: 80%;}
    }
  }
  ${Media.lg2} {
    .TxtSec {
      h3 {font-size: 30px;}
    }
  }
  ${Media.md2} {
    text-align: center; margin-bottom: 60px;
    .ImgSec {width: 100%; padding-bottom: 20px;
      img {width: 250px;}
    }
    .TxtSec {width: 100%; text-align: center; padding: 0 0 0;
      h3 {margin: 0 0 20px;}
    }
    a {margin-left: auto; margin-right: auto;}
  }
  ${Media.xs} {
    .ImgSec { padding-bottom: 10px;
      img {width: 150px;}
    }
    .TxtSec {width: 100%; text-align: center; padding: 0 0 0;
      h3 {font-size: 22px;}
      p {font-size: 16px; line-height: 1.6;}
    }
  }
`
const OddEven = styled.div `
  width: 100%; padding-bottom: 65px;
  ${ImgTxtSec} {
    &:nth-child(even) {
      .ImgSec {order: 2;}
      .TxtSec {order: 1;}
      ${Media.md2} {
        .ImgSec {order: 1;}
        .TxtSec {order: 2;}
      }
    }
  }
  ${Media.xs} { 
    padding-bottom: 0;
  }
`
const HowItWorks = styled.div `
  background: #16171e; padding: 138px 0 100px; overflow: hidden;
  h3 {font-size: 36px; margin-bottom: 42px;}
  .pointerMain {width: 100%;
    display: flex;
    .pm-img {flex-shrink: 0;}
    .pm-text {
      padding: 70px 0 0;
      li {
        list-style: none; margin-bottom: 37px;
        &:nth-child(1) {margin-left: 40px;}
        &:nth-child(2) {margin-left: -18px; margin-bottom: 45px;}
        &:nth-child(3) {margin-left: -277px;}
        h4 {font-size: 21px; font-weight: 700; margin-bottom: 16px; display: flex; align-items: center; position: relative;
          span {border: 4px solid #91dc27; width: 50px; height: 50px; display: flex; align-items: center; justify-content: center; border-radius: 100%; background: #16171e; position: absolute; left: -86px; top: calc(100% -25px);}
        }
        p {color: #a6a2b0; font-size: 18px; line-height: 1.7;}
      }
    }
  }
  ${Media.lg} {
    .pointerMain {
      .pm-text {
        li:nth-child(3) {margin-left: -407px;}
      }
    }
  }
  ${Media.lg2} {
    .pointerMain {
      .pm-text {
        padding: 50px 0 0;
        li:nth-child(1) {margin-left: 44px;}
        li:nth-child(2) {margin-left: -10px;}
        li:nth-child(3) {margin-left: -340px;}
        li {
          h4 {margin-bottom: 10px;}
          p {line-height: 1.5; font-size: 16px;}
        }
      }
    }
  }
  ${Media.md2} {
    padding: 80px 0 60px;
    .pointerMain { flex-flow: column;
      .pm-img {width: 100%; text-align: center;
        img {transform: rotate(45deg); display: inline-block; width: 400px; margin: -50px 0;}
      }
      .pm-text {padding-left: 54px; padding-top: 0;
        li:nth-child(1), li:nth-child(2), li:nth-child(3) {margin-left: 0;}
        li {
          h4 {
            span {width: 40px; height: 40px; font-size: 18px; left: -54px;}
          }
        }
      }
    }
  }
  ${Media.md2} {
    .pointerMain { flex-flow: column;
      .pm-img {width: 100%; text-align: center;
        img {transform: rotate(45deg); display: inline-block; width: 400px; margin: -50px 0;}
      }
      .pm-text {padding-left: 54px; padding-top: 0;
        li {
          h4 {font-size: 22px;}
        }
      }
    }
  }
`
const Launch = styled.div `
  padding: 150px 0;
  ${Media.md2} {
    padding: 100px 0;    
  }
  ${Media.xs} {
    padding: 60px 0;    
  }
`

export default Home;