import React, { useState } from 'react'; 
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import Home from './pages/home' 
import AirdropConnected from './pages/airdrop-connected' 
import Membership from './pages/membership' 
import Page02 from './pages/page2' 
import AirdropUser from './pages/user' 
import Gs from './theme/globalStyles';
import { ThemeProvider } from 'styled-components'
import { theme } from './theme/theme' 


function App() {   

  const [isDark,setDarkTheme] = useState(true);
  const selectedTheme = theme(isDark);

  function setTheme(flag){
    setDarkTheme(flag);    
  }

  return (
    <Router  > 
    <ThemeProvider theme={selectedTheme}>
      <section className='bodySection'>
        <Gs.GlobalStyle />  
          
          <Switch>
            <Route path="/" exact> <AirdropUser isDarkTheme={isDark}  />  </Route> 
            <Route path="/airdrop-distribution" exact> <AirdropConnected isDarkTheme={isDark}  />  </Route> 
            <Route path="/airdrop-distribution-launch" exact> <Page02 isDarkTheme={isDark}  />  </Route> 
            <Route path="/giveaway" exact> <Home isDarkTheme={isDark}  />  </Route> 
            <Route path="/membership" exact> <Membership isDarkTheme={isDark}  />  </Route> 
          </Switch> 
          
        </section>
    </ThemeProvider>
    </Router> 
  );
}

export default App;