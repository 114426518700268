import React, { Component, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, NavLink, useLocation } from 'react-router-dom';
import Media from '../theme/media-breackpoint';
import Sidebar2 from '../component/Sidebar2';
import AOS from 'aos';
import 'aos/dist/aos.css';
import rocket from '../assets/images/rocket.png';
import userImg01 from '../assets/images/userImg01.png';
import userImg02 from '../assets/images/userImg02.png';
import leftBg from '../assets/images/leftBg.png';
import leftBgD from '../assets/images/leftBg-dark.png';
import rightBg from '../assets/images/rightBg.png';
import rightBgD from '../assets/images/rightBg-dark.png';
import airdropYellow from '../assets/images/airdropYellow.png';
import chrome from '../assets/images/chrome.png';
import LogoImg from '../assets/images/logoB.png';
import logoW from '../assets/images/logoW.png';
import gift from '../assets/images/gift.png';


function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const AirdropUser = ({sidebarVisible, onClose}) => {
    let query = useQuery();
    AOS.init({
        once: true,
        duration: 1500,
    });
    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => {
        setIsHovered(true);
    };
    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const [isSidebarVisible, setSidebarVisible] = useState(sidebarVisible);
    const toggleSidebar = () => {
        setSidebarVisible(prevState => !prevState);
    };
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    return (
        <>
            <SectionMain>
            <Sidebar2 sidebarVisible={isSidebarVisible} onClose={() => setSidebarVisible(false)} />
            <PageContainer>
                <LeftSec>
                    <Logo>
                        <img className='logo-black' src={LogoImg} alt='Logo'/>
                        <img className='logo-white' src={logoW} alt='Logo'/>
                    </Logo>
                    <div className='contentMainLeft'>
                        <ContentBx>
                            <div className='at-top'>
                                <img src={userImg01} alt='userImg01' className='rightImg' />
                                <h1>Holder, earn unlimited <span>PASSIVE </span> airdrops & APY</h1>

                            </div>
                            <p>Tokens in your wallet are like cash under your mattress, they don't earn you any income. Hold your tokens in a self-custody virtual wallet and benefit from unlimited passive airdrops and unlimited APY. <RightLink target="_blank" href='membership'>Learn More </RightLink></p>
                            {/* <BtnXl>Claim YOUR $25 WELCOME BONUS <img src={airdropYellow} alt='rocket' />
                                <div className='btnTop'>
                                    <img src={chrome}/>
                                    <div className='btnTxt'>
                                        <b>Install virtual wallet</b>
                                        <i>Web Store</i>
                                    </div>
                                </div>
                            </BtnXl> */}
                            
                        </ContentBx>
                        <BtnMain onClick={toggleSidebar} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                            <BtnXl>Claim YOUR $100 WELCOME BONUS <img src={gift} alt='rocket' />
                                <div className='btnTop'>
                                    <img src={chrome} alt='chrome'/>
                                    <div className='btnTxt'>
                                        <b>Install virtual wallet</b>
                                        <i>Google web store</i>
                                    </div>
                                </div>
                            </BtnXl>
                        </BtnMain>
                    </div>
                </LeftSec>
                <RightSec className={isHovered ? 'hoverAct' : ''}>
                    <div className='contentMainRight'>
                        <ContentBx>
                            <div className='at-top'>
                                <img src={userImg02} alt='userImg02' className='rightImg' />
                                <h1>Trader, reward users with each expedite cross-chain use</h1>
                            </div>
                            <p>Expedite cross-chain transactions from long minutes to just seconds, while your expedite fee rewards other users with APY and Airdrops. <RightLink target="_blank" href='https://smart.exchange/'>Learn More</RightLink></p>
                            {/* <BtnXl className='v2'>LAUNCH AIRDROP GIVEAWAY <img src={rocket} alt='rocket' /></BtnXl> */}
                            
                        </ContentBx>
                    </div>
                </RightSec>
                {/* <FooBar>
                    <img src={rocket} height={20} alt='rocket'/> <p>Project, distributes airdrops to holders and traders for <Link to=''>FREE</Link></p>
                    <span>|</span>
                    <p> Boost your project's success by sharing your tokens or NFTs with over 10 Million+ verified wallet holders.</p> &nbsp;<Link to='/giveaway'>Learn More <i className="fas fa-angle-double-right"></i></Link>
                </FooBar> */}
            </PageContainer>
            <FooBar>
                <p><img src={rocket} height={20} alt='rocket'/> Boost your project's success by distribute for <Link to=''>FREE</Link></p>
                {/* <span>|</span> */}
                <p>&nbsp;your tokens or NFTs with over 10 Million verified wallet holders. &nbsp;<Link target="_blank" to='/giveaway'> Learn More <i className="fas fa-angle-double-right"></i></Link></p>
            </FooBar>
            </SectionMain>
        </>
    )
}

const FlexDiv = styled.div`
    display: flex; align-items: center; justify-content:center; flex-wrap:wrap;
`;

const SectionMain = styled.div `
    position: relative; min-height: 100vh; display: flex; flex-flow: wrap;
`
const PageContainer = styled.div `
    padding-bottom: 40px; display: flex;
    ${Media.md} {
        flex-flow: column; width: 100%; padding-bottom: 0;
    }
`
const Logo = styled.a ` display: flex; align-items: center; position: absolute; left: 42px; top: 34px; 
    *  {transition: all 0.3s ease-in-out;}
    .logo-black {}
    .logo-white {position: absolute; left: 0; top: 0; opacity: 0;}
    &:after {content: ""; border-left: 5px solid transparent; border-right: 5px solid transparent; border-top: 5px solid #000; margin-left: 52px;}
    ${Media.md}{
        left: 30px;
    }
    ${Media.sm}{
        img {width: 183px;}
        &:after {margin-left: 30px;}
    }
    ${Media.uxs}{
        top: 20px; left: 20px;
        img {width: 160px;}
    }
`
const RightLink = styled.a `
    margin-left: auto; font-weight: 700; color: #fff; margin-top: 20px;
    i {margin-left: 19px;}
    
`
const ContentBx = styled(FlexDiv) `
    /* position: relative; padding-bottom: 200px; */
    h3 {display: block; font-size: 16px; width: 100%; color: #16171e; margin-bottom: 22px;}
    h1 {font-size: 48px;  color: #16171e; font-weight: 700; 
        span {color: #fff; text-shadow: 0 0 15px rgba(0,0,0,0.4);}
    }
    .at-top {display: flex; flex-flow: row-reverse; width: 100%; align-items: center; justify-content: space-between; margin: 0 0 28px 0;}
    p {font-size: 18px; color: #000; line-height: 1.6; margin-bottom: 0; line-height: 1.7; min-height: 90px;}
    .rightImg {/* position: absolute; top: -15px; right: -40px; */}
    * {transition: all 0.3s ease-in-out 0s;}
    ${Media.xl}{
        h1 {font-size: 38px;}
    }
    ${Media.lg2}{
        h1 {font-size: 30px;}
    }
    ${Media.sm} {
        h1 {font-size: 24px; line-height: 1.3;}
        .at-top {margin: 0 0 10px;}
        p {font-size: 14px; line-height: 1.7;}
    }
    ${Media.uxs}{
        h1 {font-size: 20px; line-height: 1.2;}
        p {line-height: 1.5;}
    }
    
`
const BtnXl = styled.a `
    width: 820px; height: 85px; border: 0 solid #16171e; display: flex; justify-content: center; align-items: center; box-shadow: 0 0 15px 6px #fff; font-size: 24px; color: #ffff00; font-weight: 700; max-width: 100%; padding: 0 20px; text-align: center; background: #16171e; position: relative;  border-radius: 5px; overflow: hidden;
    &:hover {box-shadow: 0 0 10px #ffff00; border: 0 solid #ffff00;
        .btnTop {opacity: 1;}
    }
    img {margin-left: 25px; margin-top: -5px;}
    .btnTop {display: flex; align-items: center; font-weight: bold; position: absolute; left: 0; top: 0; right: 0; bottom: 0; color: #0d0e13; text-align: left; justify-content: center; opacity: 0; z-index: 1; background: linear-gradient(0deg, #feff00, #91dc27);
        img {filter: brightness(0); width: 38px; flex-shrink: 0; margin-right: 25px; transition: all 0.5s ease-in-out;}
        b {display: block; font-size: 24px; color: #0d0e13; transition: all 0.5s ease-in-out;}
        i {display: block; font-size: 14px; font-style: normal; transition: all 0.5s ease-in-out 0s; margin-top: 6px;}
    }
    &.v2 {color: #91dc27;
        &:hover {box-shadow: 0 0 10px #91dc27; border: 2px solid #91dc27; color: #fff;
            img {filter: drop-shadow(0 0 10px rgba(0,0,0,0.4));}
        }
    }
    ${Media.md} {
        max-width: 100%; position: relative; 
    }
    ${Media.xs} {
        font-size: 16px; height: auto; padding: 15px 15px; text-align: left; min-height: 65px;
        img {margin-left: 18px; width: 20px;} 
        .btnTop {
            b {font-size: 18px;}
            i {font-size: 12px; margin-top: 0;}
            img {margin-top: -2px; margin-right: 16px;}
        }
    }
    ${Media.uxs}{
        font-size: 13px;
        img {width: 18px; margin-right: 10px;}
    }
`
const BtnMain = styled.div `
    position: absolute; left: 100%; z-index: 10; /* width: 100vh; */ bottom: 0; display: flex; justify-content: center; transform: translateX(-50%);
    ${Media.md} {
        max-width: 100%; position: absolute; bottom: 0; left: 32px; right: 32px; transform: translateX(0%) translateY(50%);
    }
    ${Media.uxs}{
        left: 22px; right: 22px;
    }
`
const LeftSec = styled(FlexDiv) `
    margin: 0px auto; width: 50%; /* min-height: 100vh; */  background: url(${leftBg}) no-repeat #ffff00; background-size: cover; transition: all 0.3s ease-in-out 0s; 
    .contentMainLeft {padding: 0 72px 200px 120px; width: 100%; position: relative;}
    &:hover {background: url(${leftBgD}) no-repeat #0d0e13; background-size: cover;
        ${ContentBx} {
            h1 {color: #ffff00;}
            h3 {color: #a6a2b0;}
            p {color: #a6a2b0}
        }
        ${RightLink} {color: #a6a2b0;
            &:hover {color: #ffff00;}
        }
        ${Logo} {
            .logo-black {opacity: 0;}
            .logo-white {opacity: 1;}
            &:after {border-top: 5px solid #fff;}
        }
    }
    ${BtnXl}:hover {background: #ffff00; color: #fff;}
    ${Media.xl}{
        .contentMainLeft {padding: 0 70px 200px 70px;}
    }
    ${Media.lg} {
        min-height: 700px;
        .contentMainLeft { padding: 60px 30px 150px;}
        .rightImg {width: 230px; right: -30px;}
    }
    ${Media.lg2} {
        .rightImg {width: 200px;}
    }
    ${Media.md} {
        width: 100%; position: relative; height: 50vh; min-height: 500px;
        .contentMainLeft {padding: 70px 30px 60px; position: static; }
        .rightImg {width: 106px; margin: -10px -10px -10px 0;}
    }
    ${Media.xs} {
        min-height: 50vh;
    }
    ${Media.uxs} {
        .contentMainLeft {padding: 70px 20px 60px;}
    }
`
const RightSec = styled(FlexDiv) `
    margin: 0px auto; width: 50%; /* min-height: 100vh; */ background: url(${rightBg}) no-repeat #91dc27; background-size: cover; transition: all 0.3s ease-in-out 0s; overflow: hidden;
    h1 {width: 530px;}
    .contentMainRight {padding: 0 120px 200px 72px; }
    &:hover, &.hoverAct {background: url(${rightBgD}) no-repeat #0d0e13; background-size: cover;
        ${ContentBx} {
            h1 {color: #91dc27; }
            h3 {color: #a6a2b0;}
            p {color: #a6a2b0}
        }
        ${RightLink} {color: #fff;
            &:hover {color: #91dc27;}
        }
        ${BtnXl} {box-shadow: 0 0 15px 0px rgba(255,255,255,1);}
    }
    ${Media.xl}{
        .contentMainRight {padding: 0 70px 200px  70px;}
    }
    ${Media.lg} {
        min-height: 700px;
        .contentMainRight {padding: 60px 30px 150px;}
        .rightImg {width: 260px; right: -30px;}
    }
    ${Media.lg2} {
        .rightImg {width: 180px;}
    }
    ${Media.md} {
        width: 100%; height: 50vh; min-height: 500px;
        .rightImg {width: 106px;}
        .contentMainRight {padding: 70px 30px 50px;}
        h1 {width: auto;} 
    }
    ${Media.xs} {
        min-height: 50vh;
    }
    ${Media.uxs} {
        .contentMainRight {padding: 70px 20px 60px;}
    }

`

const FooBar = styled.div `
    background: #2d2e39; display: flex; justify-content: center; align-items: center; position: absolute; bottom: 0; left: 0; right: 0; font-weight: 700; font-size: 14px; padding: 10px 0; flex-flow: wrap; min-height: 40px;
    a {color: #91dc27; font-weight: 700;
        i {color: #91dc27;}
        &:hover {color: #fff;}
    }
    p {}
    img {margin: 0 12px -6px 0;}
    span {color: #7e7e7e; font-weight: 300;  margin: 0 8px;}
    ${Media.md} {
        padding: 12px 30px; text-align: center; position: relative;
    }
`


export default AirdropUser;