/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component, useEffect, useState } from 'react';
import styled from 'styled-components';
import Gs from '../theme/globalStyles';
import { Link, NavLink, useLocation } from 'react-router-dom';
import Media from '../theme/media-breackpoint';
import Collapsible from 'react-collapsible';
import Sidebar2 from '../component/Sidebar2';
import Sidebar from '../component/Sidebar';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Header from './../component/header2' 
import Footer from './../component/Footer2' 
import Bsright from '../assets/images/handBanner.png';
import rocket from '../assets/images/airdropYellow.png';
import rocket2 from '../assets/images/airdropYellow2.png';
import bannerBg from '../assets/images/banner-bg02.png';
import img01 from '../assets/images/airdropImg01.png';
import img02 from '../assets/images/img02.png';
import img03 from '../assets/images/img03.png';
import img04 from '../assets/images/img04.png';
import img05 from '../assets/images/img05.png';
import img06 from '../assets/images/img06.png';
import img07 from '../assets/images/img07.png';
import Htw01 from '../assets/images/htw01.png';
import btn01 from '../assets/images/chrome.png';
import ZeroPersent from '../assets/images/persent0.png';
import ZeroDollar from '../assets/images/dollar0.png';
import Lock from '../assets/images/locker.png';
import Lock2 from '../assets/images/locker2.png';
import ctaBg from '../assets/images/ctaBg.png';
import GiftY from '../assets/images/giftY.png';
import DMoney from '../assets/images/money.png';
import arrowBtn from '../assets/images/arrowBtn.png';
import GiftB2 from '../assets/images/giftB2.png';


function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Membership = ({sidebarVisible, onClose}) => {
    let query = useQuery();
    AOS.init({
        once: true,
        duration: 1500,
    });
    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => {
        setIsHovered(true);
    };
    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const [isSidebarVisible, setSidebarVisible] = useState(sidebarVisible);
    const toggleSidebar = () => {
        setSidebarVisible(prevState => !prevState);
    };

    const [isSidebarVisible2, setSidebarVisible2] = useState(sidebarVisible);
    const toggleSidebar2 = () => {
        setSidebarVisible2(prevState => !prevState);
    };

    const [count, setCount] = useState(0);
    const handleTriggerClick = (index) => {
      // Toggle the count state based on the index of the clicked Collapsible
      setCount(count === index ? 0 : index);
    };

    return (
      <Gs.BlackBg>
        <Sidebar2 sidebarVisible={isSidebarVisible2} onClose={() => setSidebarVisible2(false)} />
        <Sidebar sidebarVisible={isSidebarVisible} onClose={() => setSidebarVisible(false)} />
        <Header openClick={() => toggleSidebar2()} />
        <FloatingEl onClick={toggleSidebar2}>
          <p><span>$100</span> &nbsp;welcome bonus <img src={GiftB2} alt='Gift'/></p>
        </FloatingEl>
        <BannerSec>
            <Container>
              <div className='Bs-left' data-aos="fade-left">
                  <h1 className='wow fadeInUp' data-wow-delay='0.2s'>UNLIMITED <span>PASSIVE</span><br/>
                  airdrop & APY subscription</h1>
                  <p>Tokens in your wallet are like cash under your mattress, they don't earn you any income. Hold your tokens in a self-custody virtual wallet and benefit from unlimited passive airdrops and unlimited APY </p>
                  <BtnXl onClick={toggleSidebar2}>
                    <p>Claim your <span>$100</span> welcome bonus</p> <img src={GiftY} alt='rocket' />
                    <div className='btnTop'>
                      <img src={btn01} alt='chrome'/>
                      <div className='btnTxt'>
                          <b>Install Virtual Wallet</b>
                          <i>Web store</i>
                      </div>
                    </div>
                  </BtnXl>
              </div>
              <div className='Bs-right' data-aos="fade-right">
                  <img src={Bsright} alt='banner image'/>
              </div>
            </Container>
        </BannerSec>
        <Container>
          <ValueMain>
            <div className='valueCol' data-aos="fade-right">
                <img src={ZeroDollar} alt='Zero Dollar'/>
                <h3>Total Value Locked</h3>
                <p>All funds are entirely held by the user's self-custody vaults, and there is $0 exposure to any hacking.</p>
            </div>
            <div className='ValImg'>
              <img className='desk' src={Lock} alt='Lock'/> 
              <img className='responsive' src={Lock2} alt='Lock'/>
            </div>
            <div className='valueCol' data-aos="fade-left">
                <img src={ZeroPersent} alt='Zero Persent'/>
                <h3>Smart Subscription</h3>
                <p>Our vaults have zero security exposure as they rely on the security of your own original private key wallet, which is never shared with us.</p>
            </div>
          </ValueMain>
        </Container>
          
        <ImgTxtSec>
          <Container>
              <div className='ImgSec' data-aos="fade-right">
              <img src={img01} alt='img01' />
              </div>
              <div className='TxtSec' data-aos="fade-left">
                <h3>Smart Subscription for VIP access</h3>
                <p>Each project chooses which community to airdrop their tokens to. For a more efficient strategy than holding tokens from all communities, upgrade your position by holding the $SMART token. Holding the $SMART token grants you VIP access, ensuring you will participates in ALL airdrops and all APY benefits, and even gives you first access to NFT airdrops.</p>
                <p>The more $SMART tokens you hold, the more passive airdrops and benefits you will receive.</p>
                <BtnSm>BUY $SMART</BtnSm>
              </div>
          </Container>
        </ImgTxtSec>
        
        <Container>
          <BorderBox data-aos="fade-up">
              <h3>Want to be early to access the next $1 billion airdrops?
subscribe now for <span>FREE!</span> </h3>
              <BtnXl onClick={toggleSidebar2}>
                <p>Claim your <span>&nbsp;$100&nbsp;</span> welcome bonus</p> <img src={GiftY} alt='rocket' />
                <div className='btnTop'>
                  <img src={btn01} alt='chrome'/>
                  <div className='btnTxt'>
                      <b>Install Virtual Wallet</b>
                      <i>Web store</i>
                  </div>
                </div>
              </BtnXl>
          </BorderBox>

          <Faqs data-aos="fade-up">
            <h2>Frequently asked questions</h2>
            <Collapsible 
              trigger={<h4>How does it work?</h4>}
              /* open={count === 1} handleTriggerClick={() => handleTriggerClick(1)} */
            >
              <p>All crypto users hold assets in their own wallets. Some assets may be used for staking or lending, but the majority are held in wallets without generating any additional income beyond their inherent value. SmartExchange developed a new virtual wallet that acts as a layer 2 on top of all existing wallets (such as MetaMask, Trust Wallet, and others). This virtual wallet features self-custody vaults that allow users to hold their tokens and benefit from unlimited passive Airdrops and APY.</p>
            </Collapsible>
            <Collapsible 
              trigger={<h4>How secure are the self-custody vaults on Smart Exchange?</h4>}
              /* open={count === 2} handleTriggerClick={() => handleTriggerClick(2)} */
            >
              <p>Smart Exchange features a layer 2 virtual wallet with self-custody vaults that incorporate the same security features as traditional wallets, like MetaMask and Trust Wallet. This means that to deposit or withdraw any tokens from the vault, users must approve those requests using their own wallets. Therefore, if users trust their own wallets, they should have the same level of trust in their virtual wallet.</p>
            </Collapsible>


            <h2>Unlimited tokens</h2>
            <Collapsible 
              trigger={<h4>Do I need to do social or marketing tasks?</h4>}
              /* open={count === 3} handleTriggerClick={() => handleTriggerClick(3)} */
            >
              <p>No! The whole idea of the unlimited passive airdrop subscription is that users will do absolutely nothing. No social tasks, no sign-ups, no following. It's an absolutely passive approach.</p>
            </Collapsible>
            <Collapsible 
              trigger={<h4>What type of airdrop is given?</h4>}
              /* open={count === 4} handleTriggerClick={() => handleTriggerClick(4)} */
            >
              <p>Airdrops can include various types of tokens, including well-known blue chips, native tokens like ETH, stablecoins, and new tokens that have recently been launched on the market.</p>
            </Collapsible>
            <Collapsible 
              trigger={<h4>Are there any NFT airdrops?</h4>}
              /* open={count === 5} handleTriggerClick={() => handleTriggerClick(5)} */
            >
              <p>Yes, the NFT airdrops will be based on a first-come, first-claim basis.</p>
            </Collapsible>
            <Collapsible 
              trigger={<h4>Where do these airdrops originate from?</h4>}
              /* open={count === 6} handleTriggerClick={() => handleTriggerClick(6)} */
            >
              <p>Crypto projects utilize Airdrops.tube as a channel to distribute their tokens to specific community holders, ensuring that these airdrops are given out block by block over a period of time to verified users with real holdings in their virtual wallets. {/* <a href='#'>Read more</a> */}.</p>
            </Collapsible>
            <Collapsible 
              trigger={<h4>Are the airdrops limited?</h4>}
              /* open={count === 7} handleTriggerClick={() => handleTriggerClick(7)} */
            >
              <p>No, they are not! Your virtual wallet can hold a value of $X, while the total value of airdrops received can be far more valuable than that.</p>
            </Collapsible>
            <Collapsible 
              trigger={<h4>Do I need to claim the airdrops?</h4>}
              /* open={count === 8} handleTriggerClick={() => handleTriggerClick(8)} */
            >
              <p>Yes, we want to give you the freedom to claim any airdrop at any time based on your own willingness.</p>
            </Collapsible>
            <Collapsible 
              trigger={<h4>Do I need to pay gas costs for claiming?</h4>}
              /* open={count === 9} handleTriggerClick={() => handleTriggerClick(9)} */
            >
              <p>Yes, claiming airdrops requires paying gas fees, and the cost varies depending on the blockchain. It is advisable to assess the value of your accumulated airdrops compared to the gas costs to ensure cost-effectiveness.</p>
            </Collapsible>
            <Collapsible 
              trigger={<h4>How can I collect airdrops on different blockchains?</h4>}
              /* open={count === 10} handleTriggerClick={() => handleTriggerClick(10)} */
            >
              <p>Airdrops may be available on various blockchains. If you wish to access airdrops on different chains, you will need to use bridges to transfer your tokens to the destination chain and then redeposit them in the virtual wallet on that chain.</p>
            </Collapsible>


            <h2>Unlimited APY</h2>
            <Collapsible 
              trigger={<h4>Where does the unlimited APY originate from?</h4>}
              /* open={count === 11} handleTriggerClick={() => handleTriggerClick(11)} */
            >
              <p>Virtual wallet holders act as short-term lenders (for a few minutes) to allow users to expedite cross-chain transactions to seconds via SmartExchange. A portion of the expedite fee paid by these users is paid back to the vault holders in the same tokens they lent. {/* <a>Read more</a> */}.</p>
            </Collapsible>
            <Collapsible 
              trigger={<h4>Do I need to lock funds?</h4>}
              /* open={count === 12} handleTriggerClick={() => handleTriggerClick(12)} */
            >
              <p>No, the virtual wallet is like your own wallet. With self-custody vaults, you can freely add and remove funds at any time.</p>
            </Collapsible>
            <Collapsible 
              trigger={<h4>How much will the APY be?</h4>}
              /* open={count === 13} handleTriggerClick={() => handleTriggerClick(13)} */
            >
              <p>Given that there could be unlimited expedite loans occurring every few minutes, there could also be unlimited expedite fees to be paid to virtual wallet holders. All the expedite fees are distributed pro rata among the virtual wallets holding the same token that has been used for the expedite short loan. Therefore, being early or having a larger holding will result in greater benefits. {/* <a>Read more</a> */}.</p>
            </Collapsible>
            <Collapsible 
              trigger={<h4>Do I need to claim the APY?</h4>}
              /* open={count === 14} handleTriggerClick={() => handleTriggerClick(14)} */
            >
              <p>No, the APY is paid directly to your virtual wallet, and there is no need to claim it.</p>
            </Collapsible>
            <Collapsible 
              trigger={<h4>Do I need to pay gas costs for the APY?</h4>}
              /* open={count === 15} handleTriggerClick={() => handleTriggerClick(15)} */
            >
              <p>No, when users pay expedite fees, they also cover the gas cost to payback the loan and the APY fee to your virtual wallet.</p>
            </Collapsible>


            <h2>Regulation</h2>
            <Collapsible 
              trigger={<h4>How should I handle tax implications for airdrop?</h4>}
              /* open={count === 16} handleTriggerClick={() => handleTriggerClick(16)} */
            >
              <p>In some countries, airdrops are considered gifts or income and may be subject to taxation. Therefore, we built the system to allow you to decide when and if you want to claim airdrops. It is essential to check your country's tax regulations and plan accordingly when claiming airdrops to minimize tax expenses.</p>
            </Collapsible>
            <Collapsible 
              trigger={<h4>Is it legal for U.S. citizens to receive airdrops?</h4>}
              /* open={count === 17} handleTriggerClick={() => handleTriggerClick(17)} */
            >
              <p>According to U.S. law, engaging in marketing tasks or even providing an email address can be seen as a form of payment, potentially classifying the transaction as a security based on the Howey Test. However, receiving airdrops passively as a free gift, without making any payments or efforts like completing tasks, may align with the law. Please note that this is not a legal advice, and it is advisable to consult the regulations in your own country.</p>
            </Collapsible>
          </Faqs>
          <Cta data-aos="fade-up">
            <BtnSm href='https://reward.quantum.foundation/' target='_blank' className='v2'><img src={rocket2} alt='btn' /> GAIN MORE REWARDS <i className="fas fa-angle-right"/></BtnSm>
          </Cta>
          <BtnNew data-aos="fade-up" onClick={toggleSidebar} className='v2'><p>Refer & Win Up to a <span>&nbsp;$1000&nbsp;</span> Reward</p> <img src={DMoney} alt='btn' /></BtnNew>
        </Container>


          <Footer/>
        </Gs.BlackBg>
    )
}
const FlexDiv = styled.div`
  display: flex; align-items: center; justify-content:center; flex-wrap:wrap;
`;
const Container = styled(FlexDiv) `
  margin: 0px auto; width: 1360px; max-width: calc(100% - 40px);
  ${Media.lg} {width: 1100px;}
  ${Media.lg2} {width: 960px;}
`
const BannerSec = styled.div `
  width: 100%; min-height: 100vh; padding: 215px 0; background: url(${bannerBg}) no-repeat top right;
  .Bs-left {width: 50%;
    h1 {font-size: 48px; margin: 0 0 28px; color: #ffff00;
      span {color: #fff;}
    }
    p {font-size: 18px; color: #a6a2b0; line-height: 1.6; margin-bottom: 38px; line-height: 1.7;}
  } 
  .Bs-right {width: 50%; text-align: right;}
  ${Media.lg} {
    .Bs-right {padding-left: 40px;}
    .Bs-left {
      h1 {font-size: 40px;}
    }
  }
  ${Media.md2} {
    text-align: center; padding: 80px 0 100px; background: none;
    .Bs-left {width: 100%; order: 2;}
    .Bs-right {width: 100%; text-align: center; order: 1; padding: 0 0 20px;
      img {width: 400px; max-width: 100%;}
    }
    a {margin-left: auto; margin-right: auto;}
  }
  ${Media.xs} {
    min-height: inherit; padding: 80px 0 80px; 
    .Bs-left {
      h1 {font-size: 22px; margin: 0 0 14px;}
      p {margin: 0 0 20px; font-size: 16px;}
    }
    .Bs-right {
      img {width: 300px;}
    }
  }
  ${Media.uxs} {
    padding: 30px 0 80px; 
    .Bs-right {
      img {width: 200px;}
    }
  }
  
`
const BtnXl = styled.a `
  width: 662px; height: 85px; border: 2px solid #ffff00; display: flex; justify-content: center; align-items: center; box-shadow: 0 0 10px #ffff00; font-size: 21px; color: #ffffff; font-weight: 700; max-width: 100%; padding: 0 20px; text-align: center; position: relative; text-transform: uppercase; border-radius: 5px;
  p {margin: 0 !important; line-height: normal !important;}
  span {color: #ffff00;}
  img {margin-left: 25px;}
  .btnTop {display: flex; align-items: center; font-weight: bold; position: absolute; left: 0; top: 0; right: 0; bottom: 0; background: #ffff00; color: #0d0e13; text-align: left; justify-content: center; opacity: 0; z-index: 1; 
      img {filter: brightness(0); width: 37px; height: 37px; flex-shrink: 0; margin-right: 25px; transition: all 0.5s ease-in-out;}
      b {display: block; font-size: 21px; color: #0d0e13; transition: all 0.5s ease-in-out;}
      i {display: block; font-size: 14px; font-style: normal; transition: all 0.5s ease-in-out 0s; margin-top: 2px;}
  }
  &:hover {color: #fff; background: #ffff00; box-shadow: 0 0 10px #ffff00; border: 2px solid #ffff00;
    .btnTop {opacity: 1; visibility: visible;}
  }
  ${Media.sm} {
    font-size: 16px; height: 75px;
    img {margin-left: 15px; width: 25px;}

  }
  ${Media.xs} {
    height: auto; padding: 15px 15px; text-align: left;
    img {margin-left: 20px; width: 30px;} 
    .btnTop {
      padding: 0 10px;
      b {font-size: 16px;}
      i {font-size: 12px; margin-top: 0;}
      img {margin: 0 15px 0 0;}
    }
  }
  ${Media.uxs} {
    p {font-size: 14px !important;}
  }
`
const BtnSm = styled.a `
  height: 60px; border: 2px solid #ffff00; display: inline-flex; justify-content: center; align-items: center; box-shadow: 0 0 10px #ffff00; font-size: 18px; color: #ffffff; font-weight: 700; max-width: 100%; padding: 0 57px; text-align: center; align-self: flex-start; margin-top: 54px; background: #000000; border-radius: 5px;
  &:hover {box-shadow: 0 0 10px #9d9d9d; border: 2px solid #9d9d9d;}
  span {color: #ffff00;}
  img {margin-left: 25px;}
  &.v2 {
    height: 80px; margin: 0; border-color: #e9a134; padding: 0 27px;
    img {margin: 0 20px 0 0;}
    i {margin-left: 23px; font-size: 24px;}
  }
  ${Media.sm} {
    font-size: 20px; height: auto; padding: 15px 25px; text-align: left;
    img {margin-left: 20px; width: 30px;} 
  }
  ${Media.xs} {
    font-size: 16px; height: auto; padding: 12px 25px; text-align: left; margin-top: 30px;
  }
`
const ImgTxtSec = styled.div `
  width: 100%; padding-bottom: 210px; padding-top: 240px;
  .ImgSec {width: 50%; text-align: center;}
  .TxtSec {width: 50%; 
    h3 {margin: 0 0 35px; font-size: 36px; color: #fff;}
    p {font-size: 18px; color: #a6a2b0; line-height: 1.7; margin: 0;
      & + p {margin-top: 33px;}
    }
  }
  ${Media.lg} {
    .ImgSec {
      img {width: 80%;}
    }
  }
  ${Media.lg2} {
    .TxtSec {
      h3 {font-size: 30px;}
    }
  }
  ${Media.md2} {
    text-align: center; margin-bottom: 80px; padding: 0 0 0;
    .ImgSec {width: 100%; padding-bottom: 20px;
      img {width: 250px;}
    }
    .TxtSec {width: 100%; text-align: center; padding: 0 0 0;
      h3 {margin: 0 0 20px;}
    }
    a {margin-left: auto; margin-right: auto;}
  }
  ${Media.xs} {
    .ImgSec { padding-bottom: 10px;
      img {width: 150px;}
    }
    .TxtSec {width: 100%; text-align: center; padding: 0 0 0;
      h3 {font-size: 22px;}
      p {font-size: 16px; line-height: 1.6;}
    }
  }
`
const ValueMain = styled.div `
    display: flex; padding: 0 0 0; position: relative; margin-top: 0; width: 100%;
    /* &:after {position: absolute; top: 0; left: -50vw; bottom: 0; content: ""; z-index: -1; width: 100vw; margin-left: 50%;
    background-image: -moz-linear-gradient( 0deg, rgb(13,14,19) 0%, rgb(145,220,39) 50%, rgb(13,14,19) 100%);
    background-image: -webkit-linear-gradient( 0deg, rgb(13,14,19) 0%, rgb(145,220,39) 50%, rgb(13,14,19) 100%);
    background-image: -ms-linear-gradient( 0deg, rgb(13,14,19) 0%, rgb(145,220,39) 50%, rgb(13,14,19) 100%);} */
    .valueCol {
        width: 430px; max-width: 100%;
        img {margin-left: -40px;}
        h3 {font-size: 36px; font-weight: bold; margin: 0 0 28px; color: #fff;}
        p {font-size: 18px; color: #a6a2b0; margin: 0; line-height: 1.6;}
    }
    .ValImg {margin: 0 auto;
        .responsive {display: none;}
    }
    @media screen and (max-width: 1350px) {
        .valueCol {
            h3 {font-size: 30px; margin: 22px 0 30px;}
            p {font-size: 18px; line-height: 1.6;}
            
        }
    }
    ${Media.md} {
        flex-flow: column; align-items: center; padding: 0 0 80px 0;
        .valueCol { text-align: center;
            h3 {font-size: 26px; margin: 22px 0 30px;} 
            img {max-height: 130px; margin-left: 0;}
        }
        .ValImg {margin: 30px auto;
            .desk {display: none;}
            .responsive {display: block;}
        }
    }
`
const BorderBox = styled.div `
  background: #1a1a1a; border: 1px solid #ffff00; width: 100%; text-align: center; padding: 74px 110px; margin-bottom: 90px;
  h3 {font-size: 36px; margin-bottom: 48px; line-height: 1.5;
    span {color: #ffff00;}
  }
  ${BtnXl} {margin: 0 auto;}
  ${Media.lg2} {
    padding: 40px 40px;
  }
  ${Media.md2} {
    h3 {font-size: 30px; margin-bottom: 30px;}
  }
  ${Media.sm} {
    padding: 20px 25px;
    h3 {font-size: 25px;}
  }
  ${Media.uxs} {
    h3 {font-size: 20px;}
  }
`
const Faqs = styled.div `
  border-top: 1px solid #373737; padding-top: 38px;
  h2 {font-size: 36px; margin-bottom: 30px; margin-top: 57px;}
  .Collapsible {
    margin-bottom: 35px;
    .Collapsible__trigger {
      display: flex; align-items: center; justify-content: space-between;
      h4 {font-size: 24px;}
      &:after {content: "\f0d7"; font-family: "Font Awesome 5 Free"; font-weight: 900; color: #989898; font-size: 18px;}
      &.is-open {
        &:after {content: "\f0d8"; }
      }
    }
    .Collapsible__contentInner {margin-top: 23px; 
      p {color: #a6a2b0; font-size: 18px; line-height: 1.7;
        a {color: #ffff00;
          &:hover {color: #fff;}
        }
      }
    }
  }
  ${Media.sm} {
    h2 {font-size: 28px; margin-top: 60px;}
    .Collapsible {
      .Collapsible__trigger {
        h4 {font-size: 20px;}
      }
    }
  }
  ${Media.uxs} {
    h2 {font-size: 28px; margin-top: 60px;}
    .Collapsible {
      .Collapsible__trigger {
        h4 {font-size: 18px;}
      }
      .Collapsible__contentInner {
        p {font-size: 16px;}
      }
    }
  }
`
const Cta = styled(FlexDiv) `
  background: url(${ctaBg}) no-repeat; background-size: cover; width: 100%; min-height: 150px; justify-content: flex-end; padding: 0 30px; align-content: center; border: 1px solid #e9a134; margin: 102px 0;
  ${Media.sm} {
    margin: 60px 0 80px;
  }
`

const BtnNew = styled.a `
  width: 662px; height: 85px; display: flex; justify-content: center; align-items: center; box-shadow: 0 0 10px none; font-size: 24px; color: #ffffff; font-weight: 700; max-width: 100%; padding: 0 20px; text-align: center; position: relative; border-radius: 5px; border: 2px solid transparent; transition: all 0.3s ease-in-out; margin-bottom: 102px;
  span {color: #ffff00;}
  img {margin-left: 25px;}
  &:after {content: ""; position: absolute; left: -31px; top: 3px; width: 83px; height: 68px; background: url(${arrowBtn}) no-repeat; opacity: 0; transition: all 0.3s ease-in-out;}
  &:hover {box-shadow: 0 0 10px var(--primeYellow); border: 2px solid var(--primeYellow);
    &:after {opacity: 1;}
  }
  ${Media.sm} {
    font-size: 20px; margin-bottom: 80px;
    box-shadow: 0 0 10px var(--primeYellow); border: 2px solid var(--primeYellow);
    &:after {opacity: 1; transform: scale(0.7); left: -34px;}
    img {margin-left: 15px;}
  }
`
const FloatingEl = styled.div `
  position: fixed; top: calc(50% + 150px); right: 0; width: 260px; height: 44px; background: var(--primeYellow); display: flex; border-radius: 0 0 10px 10px; justify-content: center; align-items: center; color: #000; transform: rotate(90deg); transform-origin: top right; font-weight: 700; box-shadow: 0 0 20px #ffff00; cursor: pointer; z-index: 50;
  span {color: #fff; text-shadow: 0 0 10px rgba(0,0,0,0.8);}
  p {justify-content: center; align-items: center; display: flex;}
  img {margin-left: 16px; margin-top: -4px;}
  ${Media.xs} {
    transform: rotate(90deg) scale(0.7);
  }
`

export default Membership;