/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, useEffect, useState } from 'react';
import styled from 'styled-components';
import Gs from '../theme/globalStyles';
import { Link, NavLink, useLocation } from 'react-router-dom';
import Media from '../theme/media-breackpoint';
import Header from './../component/header' 
import Footer from './../component/Footer' 
import PopupContainer from '../component/PopupContainer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Bsright from '../assets/images/bs-right.png';
import icon1 from '../assets/images/icon1.png'
import icon2 from '../assets/images/icon2.png'
import icon3 from '../assets/images/icon3.png'
import icon4 from '../assets/images/icon4.png'
import Metrics from '../assets/images/Metrics.png'
import Arrow from '../assets/images/arrow-enter.png'
import Ico1 from './../assets/images/sb-ICO-01.png'
import Shield from '../assets/images/shield.png'

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';  




function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function AirdropConnected(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    let query = useQuery();
    AOS.init({
        once: true,
        duration: 1500,
    });
    const [isOpen, setIsOpen] = useState(true);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    
    const [startDate, setStartDate] = useState(new Date());

    const [isSelectTokenVisible, setSelectTokenVisible] = useState(false);
    const toggleSelectToken = () => {
        setSelectTokenVisible(!isSelectTokenVisible);
    };

    

    return (
        <>
            <Header />
            <PageContainer>
                <h1>Project, distributes airdrops to holders and traders for <span>FREE</span></h1>


                <DropdownTitle className={isOpen ? 'open' : 'close'} onClick={toggleDropdown}>
                    How it works <span className="fas fa-caret-up"/>
                </DropdownTitle>
                {isOpen && (<DropContent>
                    <div className='DcSec'>
                        <b>1</b>
                        <div>
                            <h4>Initiate your airdrop campaign</h4>
                            <p>Deposit the airdrop supply and select the groups of claimers you wish to distribute your airdrop to, easily by choosing the tokens those claimers are already holding. For example, you can airdrop your token to all ETH or SHIB holders. You may select unlimited claimer groups as you like, even if there are more than a million holders in total. There is no need to upload any wallet addresses for the claimers, our system will handle the matching for you. Choose a start and end date, and if necessary, specify any supply limitation per holder. Based on that period, your supply will be distributed block by block pro-rata to ensure that no holder receives a large amount of the airdrop instantly, which could create a risk of dumping to your token price.</p>
                        </div>
                    </div>
                    <div className='DcSec'>
                        <b>2</b>
                        <div>
                            <h4>Launch airdrop engagement</h4>
                            <p>Our system will notify your chosen claimers about your airdrops and invite them to claim it at any time, while they cover the claiming cost. This means there is no distribution gas cost for you! even if you airdrop an Ethereum-based token to millions of claimers. no only that even your distribution airdrop fee will be paid with your own tokens and will be distributed to $SMART token holders as airdrop as well, so you will gain another verified claimers group that will join your community.</p>
                        </div>
                    </div>
                    <div className='DcSec'>
                        <b>3</b>
                        <div>
                            <h4>Manage your airdrop supply</h4>
                            <p>Adjust your airdrop supply, adding more or withdrawing unclaimed tokens at any time back to your wallet.</p>
                        </div>
                    </div>
                </DropContent>)}
                {/* <TopMenu>
                    <a onClick={() => toggleTab(1)} className={getActiveClass(1, 'active')}>Distribute airdrops to holders <span className='badge'>23</span></a>
                    <a onClick={() => toggleTab(2)} className={getActiveClass(2, 'active')}>Distribute airdrops to traders</a>
                </TopMenu> */}

                <TabContent>
                    <>
                    <TableContainer className='no-border'>
                        <TableTop>
                            <Btn className='btn-lg hover-white'>CONNECT WALLET</Btn>
                        </TableTop>
                        <VerticalSlider>
                            <div className='VsHead'>Chain</div>
                            <span><img src={icon1} alt='fail'/></span>
                            <span><img src={icon2} alt='fail'/></span>
                            <span><img src={icon3} alt='fail'/></span>
                            <span><img src={icon4} alt='fail'/></span>
                            <span><img src={icon1} alt='fail'/></span>
                            <span><img src={icon2} alt='fail'/></span>
                            <span><img src={icon3} alt='fail'/></span>
                            <span><img src={icon4} alt='fail'/></span>
                            <span><img src={icon1} alt='fail'/></span>
                            <span><img src={icon2} alt='fail'/></span>
                            <span><img src={icon3} alt='fail'/></span>
                            <span><img src={icon4} alt='fail'/></span>
                            <span><img src={icon1} alt='fail'/></span>
                            <span><img src={icon2} alt='fail'/></span>
                        </VerticalSlider>
                        <TableScroll>
                        <table>
                            <tr>
                                <th className='w-10'>Airdrop token 
                                    <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                </th>
                                <th className='w-10'>Airdrop supply <i className="fas fa-question-circle"></i> 
                                    <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                </th>
                                <th colSpan={2} className='w-20'>Claimers groups 
                                    <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                </th>
                                <th className='w-10'>Total claimers <i className="fas fa-question-circle"></i>
                                    <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>

                                </th>
                                <th className='w-10'>Drop progress <i className="fas fa-question-circle"></i> 
                                    <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                </th>
                                <th className='w-10'>Due date <i className="fas fa-question-circle"></i>
                                    <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                </th>
                                <th className='w-10'>Supply balance <i className="fas fa-question-circle"></i>
                                    <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                </th>
                                <th className='w-10 t-center'>Add supply </th>
                            </tr>
                            <tr className='trHighlight'>
                                <td>
                                    <Token><img src={icon1}/> SMART</Token>
                                    <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                </td>
                                <td>100,000,000</td>
                                <td colSpan={2}>
                                    <Token className='lg'>
                                    <img src={icon3} alt='fail'/>
                                    <img src={icon2} alt='fail'/>
                                    <img src={icon1} alt='fail'/>
                                    <img src={icon4} alt='fail'/>
                                    <img src={icon1} alt='fail'/>
                                    <img src={icon2} alt='fail'/>
                                    <img src={icon3} alt='fail'/>
                                    <img src={icon4} alt='fail'/>
                                    <img src={icon1} alt='fail'/>
                                    <img src={icon2} alt='fail'/>
                                    +10
                                    </Token>
                                </td>
                                <td className='color-green'>100.583
                                    <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                </td>
                                <td className='hasBtn'>
                                    <Progress><b style={{width: '20%'}} /></Progress>
                                    <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                </td>
                                <td><DatePicker dateFormat="MMMM d, yyyy h:mm aa"   selected={startDate} onChange={(date) => setStartDate(date)} /></td>
                                <td className='color-blue hasBtn'>1,000,000,000 
                                    <a className='claim color-white'>CONNECT WALLET</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                </td>
                                <td><a onClick={toggleSelectToken} className='btn01 white'>CONNECT WALLET</a></td>
                            </tr>
                            <tr className='trHighlight'>
                                <td>
                                    <Token><img src={icon1} alt='fail'/> SMART</Token>
                                    <a className='arrow-dwn color-green'><i className="fas fa-caret-up"/></a>
                                </td>
                                <td>100,000,000</td>
                                <td colSpan={2}>
                                    <Token className='lg'>
                                    <img src={icon1} alt='fail'/>
                                    <img src={icon2} alt='fail'/>
                                    <img src={icon3} alt='fail'/>
                                    <img src={icon4} alt='fail'/>
                                    <img src={icon1} alt='fail'/>
                                    <img src={icon2} alt='fail'/>
                                    <img src={icon3} alt='fail'/>
                                    <img src={icon4} alt='fail'/>
                                    <img src={icon1} alt='fail'/>
                                    <img src={icon2} alt='fail'/>
                                    +10
                                    </Token>
                                </td>
                                <td className='color-green'>100.583
                                    <a className='arrow-dwn color-green'><i className="fas fa-caret-up"/></a>
                                </td>
                                <td className='hasBtn'>
                                    <Progress><b style={{width: '20%'}} /></Progress>
                                    <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                </td>
                                <td>April 12, 2024 12:00pm UTC</td>
                                <td className='color-blue hasBtn'>1,000,000,000 
                                    <a className='claim color-white'>CONNECT WALLET</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                </td>
                                <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                            </tr>

                            <tBody className="tableHighlight">
                                <tr>
                                    <th className='w-10'>Airdrop token </th>
                                    <th className='w-10'>Airdrop supply <i className="fas fa-question-circle"></i> 
                                        <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                    </th>
                                    <th className='w-10'>Claimers groups 
                                        <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                    </th>
                                    <th className='w-10'>Metrics
                                        <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                    </th>
                                    <th className='w-10'>Total claimers <i className="fas fa-question-circle"></i>
                                        <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>

                                    </th>
                                    <th className='w-10'>Drop progress <i className="fas fa-question-circle"></i> 
                                        <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                    </th>
                                    <th className='w-10'>Due date <i className="fas fa-question-circle"></i>
                                        <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                    </th>
                                    <th className='w-10'>Balance <i className="fas fa-question-circle"></i>
                                        <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                    </th>
                                    <th className='w-10 t-center'>Add supply </th>
                                </tr>
                                <tr>
                                    <td>
                                        <img src={Arrow} alt='fail'/> 
                                    </td>
                                    <td>100,000,000</td>
                                    <td >
                                        <Token><img src={icon1} alt='fail'/></Token>
                                    </td>
                                    <td >
                                        <Token className='color-blue'><img src={Metrics} alt='fail'/>  View detail</Token>
                                    </td>
                                    <td className='color-green'>10,058
                                        <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                    </td>
                                    <td className='hasBtn'>
                                        <Progress><b style={{width: '20%'}} /></Progress>
                                        <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                    </td>
                                    <td>April 12, 2024 12:00pm UTC</td>
                                    <td className='color-blue hasBtn'>1,000,000,000 
                                        <a className='claim color-white'>CONNECT WALLET</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                    </td>
                                    <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src={Arrow} alt='fail'/> 
                                    </td>
                                    <td>100,000,000</td>
                                    <td >
                                        <Token><img src={icon1} alt='fail'/></Token>
                                    </td>
                                    <td >
                                        <Token className='color-blue'><img src={Metrics} alt='fail'/>  View detail</Token>
                                    </td>
                                    <td className='color-green'>10,058
                                        <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                    </td>
                                    <td className='hasBtn'>
                                        <Progress><b style={{width: '20%'}} /></Progress>
                                        <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                    </td>
                                    <td>April 12, 2024 12:00pm UTC</td>
                                    <td className='color-blue hasBtn'>1,000,000,000 
                                        <a className='claim color-white'>CONNECT WALLET</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                    </td>
                                    <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src={Arrow} alt='fail'/> 
                                    </td>
                                    <td>100,000,000</td>
                                    <td >
                                        <Token><img src={icon1} alt='fail'/></Token>
                                    </td>
                                    <td >
                                        <Token className='color-blue'><img src={Metrics} alt='fail'/>  View detail</Token>
                                    </td>
                                    <td className='color-green'>10,058
                                        <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                    </td>
                                    <td className='hasBtn'>
                                        <Progress><b style={{width: '20%'}} /></Progress>
                                        <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                    </td>
                                    <td>April 12, 2024 12:00pm UTC</td>
                                    <td className='color-blue hasBtn'>1,000,000,000 
                                        <a className='claim color-white'>CONNECT WALLET</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                    </td>
                                    <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src={Arrow} alt='fail'/> 
                                    </td>
                                    <td>100,000,000</td>
                                    <td >
                                        <Token><img src={icon1} alt='fail'/></Token>
                                    </td>
                                    <td >
                                        <Token className='color-blue'><img src={Metrics} alt='fail'/>  View detail</Token>
                                    </td>
                                    <td className='color-green'>10,058
                                        <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                    </td>
                                    <td className='hasBtn'>
                                        <Progress><b style={{width: '20%'}} /></Progress>
                                        <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                    </td>
                                    <td>April 12, 2024 12:00pm UTC</td>
                                    <td className='color-blue hasBtn'>1,000,000,000 
                                        <a className='claim color-white'>CONNECT WALLET</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                    </td>
                                    <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src={Arrow} alt='fail'/>  
                                    </td>
                                    <td>100,000,000</td>
                                    <td >
                                        <Token><img src={icon1} alt='fail'/></Token>
                                    </td>
                                    <td >
                                        <Token className='color-blue'><img src={Metrics} alt='fail'/>  View detail</Token>
                                    </td>
                                    <td className='color-green'>10,058
                                        <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                    </td>
                                    <td className='hasBtn'>
                                        <Progress><b style={{width: '20%'}} /></Progress>
                                        <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                    </td>
                                    <td>April 12, 2024 12:00pm UTC</td>
                                    <td className='color-blue hasBtn'>1,000,000,000 
                                        <a className='claim color-white'>CONNECT WALLET</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                    </td>
                                    <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src={Arrow} alt='fail'/> 
                                    </td>
                                    <td>100,000,000</td>
                                    <td >
                                        <Token><img src={icon1} alt='fail'/></Token>
                                    </td>
                                    <td >
                                        <Token className='color-blue'><img src={Metrics} alt='fail'/>  View detail</Token>
                                    </td>
                                    <td className='color-green'>10,058
                                        <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                    </td>
                                    <td className='hasBtn'>
                                        <Progress><b style={{width: '20%'}} /></Progress>
                                        <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                    </td>
                                    <td>April 12, 2024 12:00pm UTC</td>
                                    <td className='color-blue hasBtn'>1,000,000,000 
                                        <a className='claim color-white'>CONNECT WALLET</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                    </td>
                                    <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src={Arrow} alt='fail'/> 
                                    </td>
                                    <td>100,000,000</td>
                                    <td >
                                        <Token><img src={icon1} alt='fail'/></Token>
                                    </td>
                                    <td >
                                        <Token className='color-blue'><img src={Metrics} alt='fail'/>  View detail</Token>
                                    </td>
                                    <td className='color-green'>10,058
                                        <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                    </td>
                                    <td className='hasBtn'>
                                        <Progress><b style={{width: '20%'}} /></Progress>
                                        <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                    </td>
                                    <td>April 12, 2024 12:00pm UTC</td>
                                    <td className='color-blue hasBtn'>1,000,000,000 
                                        <a className='claim color-white'>CONNECT WALLET</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                    </td>
                                    <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                                </tr>
                            </tBody>

                            <tr>
                                <td>
                                    <Token><img src={icon1} alt='fail'/> SMART</Token>
                                    <a className='arrow-dwn color-green'><i className="fas fa-caret-up"/></a>
                                </td>
                                <td>100,000,000</td>
                                <td colSpan={2}>
                                    <Token className='lg'>
                                    <img src={icon1} alt='fail'/>
                                    <img src={icon2} alt='fail'/>
                                    <img src={icon3} alt='fail'/>
                                    <img src={icon4} alt='fail'/>
                                    <img src={icon1} alt='fail'/>
                                    <img src={icon2} alt='fail'/>
                                    <img src={icon3} alt='fail'/>
                                    <img src={icon4} alt='fail'/>
                                    <img src={icon1} alt='fail'/>
                                    <img src={icon2} alt='fail'/>
                                    +10
                                    </Token>
                                </td>
                                <td className='color-green'>100.583
                                    <a className='arrow-dwn color-green'><i className="fas fa-caret-up"/></a>
                                </td>
                                <td className='hasBtn'>
                                    <Progress><b style={{width: '20%'}} /></Progress>
                                    <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                </td>
                                <td>April 12, 2024 12:00pm UTC</td>
                                <td className='color-blue hasBtn'>1,000,000,000 
                                    <a className='claim color-white'>CONNECT WALLET</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                </td>
                                <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                            </tr>
                            <tr>
                                <td>
                                    <Token><img src={icon1} alt='fail'/> SMART</Token>
                                    <a className='arrow-dwn color-green'><i className="fas fa-caret-up"/></a>
                                </td>
                                <td>100,000,000</td>
                                <td colSpan={2}>
                                    <Token className='lg'>
                                    <img src={icon1} alt='fail'/>
                                    <img src={icon2} alt='fail'/>
                                    <img src={icon3} alt='fail'/>
                                    <img src={icon4} alt='fail'/>
                                    <img src={icon1} alt='fail'/>
                                    <img src={icon2} alt='fail'/>
                                    <img src={icon3} alt='fail'/>
                                    <img src={icon4} alt='fail'/>
                                    <img src={icon1} alt='fail'/>
                                    <img src={icon2} alt='fail'/>
                                    +10
                                    </Token>
                                </td>
                                <td className='color-green'>100.583
                                    <a className='arrow-dwn color-green'><i className="fas fa-caret-up"/></a>
                                </td>
                                <td className='hasBtn'>
                                    <Progress><b style={{width: '20%'}} /></Progress>
                                    <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                </td>
                                <td>April 12, 2024 12:00pm UTC</td>
                                <td className='color-blue hasBtn'>1,000,000,000 
                                    <a className='claim color-white'>CONNECT WALLET</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                </td>
                                <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                            </tr>
                            <tr>
                                <td>
                                    <Token><img src={icon1} alt='fail'/> SMART</Token>
                                    <a className='arrow-dwn color-green'><i className="fas fa-caret-up"/></a>
                                </td>
                                <td>100,000,000</td>
                                <td colSpan={2}>
                                    <Token className='lg'>
                                    <img src={icon1} alt='fail'/>
                                    <img src={icon2} alt='fail'/>
                                    <img src={icon3} alt='fail'/>
                                    <img src={icon4} alt='fail'/>
                                    <img src={icon1} alt='fail'/>
                                    <img src={icon2} alt='fail'/>
                                    <img src={icon3} alt='fail'/>
                                    <img src={icon4} alt='fail'/>
                                    <img src={icon1} alt='fail'/>
                                    <img src={icon2} alt='fail'/>
                                    +10
                                    </Token>
                                </td>
                                <td className='color-green'>100.583
                                    <a className='arrow-dwn color-green'><i className="fas fa-caret-up"/></a>
                                </td>
                                <td className='hasBtn'>
                                    <Progress><b style={{width: '20%'}} /></Progress>
                                    <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                </td>
                                <td>April 12, 2024 12:00pm UTC</td>
                                <td className='color-blue hasBtn'>1,000,000,000 
                                    <a className='claim color-white'>CONNECT WALLET</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                </td>
                                <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                            </tr>
                            <tr>
                                <td>
                                    <Token><img src={icon1} alt='fail'/> SMART</Token>
                                    <a className='arrow-dwn color-green'><i className="fas fa-caret-up"/></a>
                                </td>
                                <td>100,000,000</td>
                                <td colSpan={2}>
                                    <Token className='lg'>
                                    <img src={icon1} alt='fail'/>
                                    <img src={icon2} alt='fail'/>
                                    <img src={icon3} alt='fail'/>
                                    <img src={icon4} alt='fail'/>
                                    <img src={icon1} alt='fail'/>
                                    <img src={icon2} alt='fail'/>
                                    <img src={icon3} alt='fail'/>
                                    <img src={icon4} alt='fail'/>
                                    <img src={icon1} alt='fail'/>
                                    <img src={icon2} alt='fail'/>
                                    +10
                                    </Token>
                                </td>
                                <td className='color-green'>100.583
                                    <a className='arrow-dwn color-green'><i className="fas fa-caret-up"/></a>
                                </td>
                                <td className='hasBtn'>
                                    <Progress><b style={{width: '20%'}} /></Progress>
                                    <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                </td>
                                <td>April 12, 2024 12:00pm UTC</td>
                                <td className='color-blue hasBtn'>1,000,000,000 
                                    <a className='claim color-white'>CONNECT WALLET</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                </td>
                                <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                            </tr>
                            <tr>
                                <td className='nobo' colSpan={7}>
                                </td>
                                <td className='nobo'><a onClick={toggleSelectToken} className='btn01 white'>CONNECT WALLET</a></td>
                                <td className='nobo'></td>
                                
                            </tr>
                        </table>
                        </TableScroll>
                    </TableContainer>

                    <TableContainer>
                        <TableTop>
                            <Btn className='btn-lg hover-white'>CONNECT WALLET</Btn>
                        </TableTop>
                        <VerticalSlider>
                            <div className='VsHead'>Chain</div>
                            <span><img src={icon1} alt='fail'/></span>
                            <span><img src={icon2} alt='fail'/></span>
                            <span><img src={icon3} alt='fail'/></span>
                            <span><img src={icon4} alt='fail'/></span>
                            <span><img src={icon1} alt='fail'/></span>
                            <span><img src={icon2} alt='fail'/></span>
                            <span><img src={icon3} alt='fail'/></span>
                            <span><img src={icon4} alt='fail'/></span>
                            <span><img src={icon1} alt='fail'/></span>
                            <span><img src={icon2} alt='fail'/></span>
                            <span><img src={icon3} alt='fail'/></span>
                            <span><img src={icon4} alt='fail'/></span>
                            <span><img src={icon1} alt='fail'/></span>
                            <span><img src={icon2} alt='fail'/></span>
                        </VerticalSlider>
                        <TableScroll>
                        <table>
                            <tr>
                                <th className='w-10'>Airdrop token 
                                    <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                </th>
                                <th className='w-10'>Airdrop supply <i className="fas fa-question-circle"></i> 
                                    <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                </th>
                                <th className='w-10'>NFT supply 
                                    <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                </th>
                                <th colSpan={2} className='w-20'>Claimers groups 
                                    <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                </th>
                                <th className='w-10'>Total claimers <i className="fas fa-question-circle"></i>
                                    <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>

                                </th>
                                <th className='w-10'>Drop progress <i className="fas fa-question-circle"></i> 
                                    <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                </th>
                                <th className='w-10'>Supply balance <i className="fas fa-question-circle"></i>
                                    <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                </th>
                                <th className='w-10 t-center'>Add supply </th>
                            </tr>
                            <tr className='trHighlight'>
                                <td>
                                    <Token><img src={icon1} alt='fail'/> Bored Ape Yatch...</Token>
                                    <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                </td>
                                <td><Token><img src={icon1} alt='fail'/> Bored Ape Yatch...</Token></td>
                                <td>10,000</td>
                                <td colSpan={2}>
                                    <Token className='lg'>
                                    <img src={icon1} alt='fail'/>
                                    <img src={icon2} alt='fail'/>
                                    <img src={icon3} alt='fail'/>
                                    <img src={icon4} alt='fail'/>
                                    <img src={icon1} alt='fail'/>
                                    <img src={icon2} alt='fail'/>
                                    <img src={icon3} alt='fail'/>
                                    <img src={icon4} alt='fail'/>
                                    <img src={icon1} alt='fail'/>
                                    <img src={icon2} alt='fail'/>
                                    +10
                                    </Token>
                                </td>
                                <td className='color-green'>100.583
                                    <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                </td>
                                <td className='hasBtn'>
                                    <Progress><b style={{width: '20%'}} /></Progress>
                                    <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                </td>
                                <td className='color-blue hasBtn'>1,000,000,000 
                                    <a className='claim color-white'>CONNECT WALLET</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                </td>
                                <td><a onClick={toggleSelectToken} className='btn01 white'>CONNECT WALLET</a></td>
                            </tr>
                            <tr className='trHighlight'>
                                <td>
                                    <Token><img src={icon1} alt='fail'/>  Bored Ape Yatch...</Token>
                                    <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                </td>
                                <td><Token><img src={icon1} alt='fail'/> Bored Ape Yatch...</Token></td>
                                <td>10,000</td>
                                <td colSpan={2}>
                                    <Token className='lg'>
                                    <img src={icon1} alt='fail'/>
                                    <img src={icon2} alt='fail'/>
                                    <img src={icon3} alt='fail'/>
                                    <img src={icon4} alt='fail'/>
                                    <img src={icon1} alt='fail'/>
                                    <img src={icon2} alt='fail'/>
                                    <img src={icon3} alt='fail'/>
                                    <img src={icon4} alt='fail'/>
                                    <img src={icon1} alt='fail'/>
                                    <img src={icon2} alt='fail'/>
                                    +10
                                    </Token>
                                </td>
                                <td className='color-green'>100.583
                                    <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                </td>
                                <td className='hasBtn'>
                                    <Progress><b style={{width: '20%'}} /></Progress>
                                    <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                </td>
                                <td className='color-blue hasBtn'>1,000,000,000 
                                    <a className='claim color-white'>CONNECT WALLET</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                </td>
                                <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                            </tr>

                            <tBody className="tableHighlight">
                                <tr>
                                    <th className='w-10'>Airdrop token </th>
                                    <th className='w-10'>Airdrop supply <i className="fas fa-question-circle"></i> 
                                        <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                    </th>
                                    <th className='w-10'>NFT supply
                                        <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                    </th>
                                    <th className='w-10'>Claimers groups 
                                        <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                    </th>
                                    <th className='w-10'>Metrics
                                        <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                    </th>
                                    <th className='w-10'>Total claimers <i className="fas fa-question-circle"></i>
                                        <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>

                                    </th>
                                    <th className='w-10'>Drop progress <i className="fas fa-question-circle"></i> 
                                        <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                    </th>
                                    <th className='w-10'>Balance <i className="fas fa-question-circle"></i>
                                        <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                    </th>
                                    <th className='w-10 t-center'>Add supply </th>
                                </tr>
                                <tr>
                                    <td>
                                        <img src={Arrow} alt='fail'/> 
                                    </td>
                                    <td>
                                        <img src={Arrow} alt='fail'/> 
                                    </td>
                                    <td>1,000</td>
                                    <td>
                                        <Token><img src={icon1} alt='fail'/></Token>
                                    </td>
                                    <td >
                                        <Token className='color-blue'><img src={Metrics} alt='fail'/>  View detail</Token>
                                    </td>
                                    <td className='color-green'>10,058
                                        <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                    </td>
                                    <td className='hasBtn'>
                                        <Progress><b style={{width: '20%'}} /></Progress>
                                        <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                    </td>
                                    <td className='color-blue hasBtn'>1,000,000,000 
                                        <a className='claim color-white'>CONNECT WALLET</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                    </td>
                                    <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src={Arrow} alt='fail'/> 
                                    </td>
                                    <td>
                                        <img src={Arrow} alt='fail'/> 
                                    </td>
                                    <td>1,000</td>
                                    <td>
                                        <Token><img src={icon1} alt='fail'/></Token>
                                    </td>
                                    <td >
                                        <Token className='color-blue'><img src={Metrics} alt='fail'/>  View detail</Token>
                                    </td>
                                    <td className='color-green'>10,058
                                        <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                    </td>
                                    <td className='hasBtn'>
                                        <Progress><b style={{width: '20%'}} /></Progress>
                                        <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                    </td>
                                    <td className='color-blue hasBtn'>1,000,000,000 
                                        <a className='claim color-white'>CONNECT WALLET</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                    </td>
                                    <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src={Arrow} alt='fail'/> 
                                    </td>
                                    <td>
                                        <img src={Arrow} alt='fail'/>  
                                    </td>
                                    <td>1,000</td>
                                    <td>
                                        <Token><img src={icon1} alt='fail'/></Token>
                                    </td>
                                    <td >
                                        <Token className='color-blue'><img src={Metrics} alt='fail'/>  View detail</Token>
                                    </td>
                                    <td className='color-green'>10,058
                                        <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                    </td>
                                    <td className='hasBtn'>
                                        <Progress><b style={{width: '20%'}} /></Progress>
                                        <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                    </td>
                                    <td className='color-blue hasBtn'>1,000,000,000 
                                        <a className='claim color-white'>CONNECT WALLET</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                    </td>
                                    <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src={Arrow} alt='fail'/> 
                                    </td>
                                    <td>
                                        <img src={Arrow} alt='fail'/> 
                                    </td>
                                    <td>1,000</td>
                                    <td>
                                        <Token><img src={icon1} alt='fail'/></Token>
                                    </td>
                                    <td >
                                        <Token className='color-blue'><img src={Metrics} alt='fail'/>  View detail</Token>
                                    </td>
                                    <td className='color-green'>10,058
                                        <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                    </td>
                                    <td className='hasBtn'>
                                        <Progress><b style={{width: '20%'}} /></Progress>
                                        <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                    </td>
                                    <td className='color-blue hasBtn'>1,000,000,000 
                                        <a className='claim color-white'>CONNECT WALLET</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                    </td>
                                    <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src={Arrow} alt='fail'/> 
                                    </td>
                                    <td>
                                        <img src={Arrow} alt='fail'/> 
                                    </td>
                                    <td>1,000</td>
                                    <td>
                                        <Token><img src={icon1} alt='fail'/></Token>
                                    </td>
                                    <td >
                                        <Token className='color-blue'><img src={Metrics} alt='fail'/>  View detail</Token>
                                    </td>
                                    <td className='color-green'>10,058
                                        <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                    </td>
                                    <td className='hasBtn'>
                                        <Progress><b style={{width: '20%'}} /></Progress>
                                        <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                    </td>
                                    <td className='color-blue hasBtn'>1,000,000,000 
                                        <a className='claim color-white'>CONNECT WALLET</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                    </td>
                                    <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src={Arrow}/> 
                                    </td>
                                    <td>
                                        <img src={Arrow}/> 
                                    </td>
                                    <td>1,000</td>
                                    <td>
                                        <Token><img src={icon1}/></Token>
                                    </td>
                                    <td >
                                        <Token className='color-blue'><img src={Metrics}/>  View detail</Token>
                                    </td>
                                    <td className='color-green'>10,058
                                        <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                    </td>
                                    <td className='hasBtn'>
                                        <Progress><b style={{width: '20%'}} /></Progress>
                                        <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                    </td>
                                    <td className='color-blue hasBtn'>1,000,000,000 
                                        <a className='claim color-white'>CONNECT WALLET</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                    </td>
                                    <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                                </tr>
                            </tBody>

                            <tr className='trHighlight'>
                                <td>
                                    <Token><img src={icon1}/> Bored Ape Yatch...</Token>
                                    <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                </td>
                                <td><Token><img src={icon1}/> Bored Ape Yatch...</Token></td>
                                <td>10,000</td>
                                <td colSpan={2}>
                                    <Token className='lg'>
                                    <img src={icon1}/>
                                    <img src={icon2}/>
                                    <img src={icon3}/>
                                    <img src={icon4}/>
                                    <img src={icon1}/>
                                    <img src={icon2}/>
                                    <img src={icon3}/>
                                    <img src={icon4}/>
                                    <img src={icon1}/>
                                    <img src={icon2}/>
                                    +10
                                    </Token>
                                </td>
                                <td className='color-green'>100.583
                                    <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                </td>
                                <td className='hasBtn'>
                                    <Progress><b style={{width: '20%'}} /></Progress>
                                    <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                </td>
                                <td className='color-blue hasBtn'>1,000,000,000 
                                    <a className='claim color-white'>CONNECT WALLET</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                </td>
                                <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                            </tr>
                            <tr className='trHighlight'>
                                <td>
                                    <Token><img src={icon1}/> Bored Ape Yatch...</Token>
                                    <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                </td>
                                <td><Token><img src={icon1}/> Bored Ape Yatch...</Token></td>
                                <td>10,000</td>
                                <td colSpan={2}>
                                    <Token className='lg'>
                                    <img src={icon1}/>
                                    <img src={icon2}/>
                                    <img src={icon3}/>
                                    <img src={icon4}/>
                                    <img src={icon1}/>
                                    <img src={icon2}/>
                                    <img src={icon3}/>
                                    <img src={icon4}/>
                                    <img src={icon1}/>
                                    <img src={icon2}/>
                                    +10
                                    </Token>
                                </td>
                                <td className='color-green'>100.583
                                    <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                </td>
                                <td className='hasBtn'>
                                    <Progress><b style={{width: '20%'}} /></Progress>
                                    <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                </td>
                                <td className='color-blue hasBtn'>1,000,000,000 
                                    <a className='claim color-white'>CONNECT WALLET</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                </td>
                                <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                            </tr>
                            <tr className='trHighlight'>
                                <td>
                                    <Token><img src={icon1}/> Bored Ape Yatch...</Token>
                                    <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                </td>
                                <td><Token><img src={icon1}/> Bored Ape Yatch...</Token></td>
                                <td>10,000</td>
                                <td colSpan={2}>
                                    <Token className='lg'>
                                    <img src={icon1}/>
                                    <img src={icon2}/>
                                    <img src={icon3}/>
                                    <img src={icon4}/>
                                    <img src={icon1}/>
                                    <img src={icon2}/>
                                    <img src={icon3}/>
                                    <img src={icon4}/>
                                    <img src={icon1}/>
                                    <img src={icon2}/>
                                    +10
                                    </Token>
                                </td>
                                <td className='color-green'>100.583
                                    <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                </td>
                                <td className='hasBtn'>
                                    <Progress><b style={{width: '20%'}} /></Progress>
                                    <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                </td>
                                <td className='color-blue hasBtn'>1,000,000,000 
                                    <a className='claim color-white'>CONNECT WALLET</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                </td>
                                <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                            </tr>
                            <tr className='trHighlight'>
                                <td>
                                    <Token><img src={icon1}/> Bored Ape Yatch...</Token>
                                    <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                </td>
                                <td><Token><img src={icon1}/> Bored Ape Yatch...</Token></td>
                                <td>10,000</td>
                                <td colSpan={2}>
                                    <Token className='lg'>
                                    <img src={icon1}/>
                                    <img src={icon2}/>
                                    <img src={icon3}/>
                                    <img src={icon4}/>
                                    <img src={icon1}/>
                                    <img src={icon2}/>
                                    <img src={icon3}/>
                                    <img src={icon4}/>
                                    <img src={icon1}/>
                                    <img src={icon2}/>
                                    +10
                                    </Token>
                                </td>
                                <td className='color-green'>100.583
                                    <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                </td>
                                <td className='hasBtn'>
                                    <Progress><b style={{width: '20%'}} /></Progress>
                                    <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                </td>
                                <td className='color-blue hasBtn'>1,000,000,000 
                                    <a className='claim color-white'>CONNECT WALLET</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                </td>
                                <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                            </tr>
                            <tr className='trHighlight'>
                                <td>
                                    <Token><img src={icon1}/> Bored Ape Yatch...</Token>
                                    <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                </td>
                                <td><Token><img src={icon1}/> Bored Ape Yatch...</Token></td>
                                <td>10,000</td>
                                <td colSpan={2}>
                                    <Token className='lg'>
                                    <img src={icon1}/>
                                    <img src={icon2}/>
                                    <img src={icon3}/>
                                    <img src={icon4}/>
                                    <img src={icon1}/>
                                    <img src={icon2}/>
                                    <img src={icon3}/>
                                    <img src={icon4}/>
                                    <img src={icon1}/>
                                    <img src={icon2}/>
                                    +10
                                    </Token>
                                </td>
                                <td className='color-green'>100.583
                                    <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                </td>
                                <td className='hasBtn'>
                                    <Progress><b style={{width: '20%'}} /></Progress>
                                    <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                </td>
                                <td className='color-blue hasBtn'>1,000,000,000 
                                    <a className='claim color-white'>CONNECT WALLET</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                </td>
                                <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                            </tr>
                            
                            <tr>
                                <td className='nobo' colSpan={7}>
                                </td>
                                <td className='nobo'>
                                    <p><span>Total:</span> <span>1,000,000,000,000</span></p>
                                </td>
                                <td className='nobo'></td>
                            </tr>
                        </table>
                        </TableScroll>
                    </TableContainer>
                    </>
                    <>
                    <TableContainer className='no-border'>
                        <TableTop>
                            <Btn as={Link} to="/airdrop-distribution-launch" className='btn-lg green-border'>Launch a new airdrop distribution</Btn>
                        </TableTop>
                        <VerticalSlider>
                            <div className='VsHead'>Chain</div>
                            <span><img src={icon1}/></span>
                            <span><img src={icon2}/></span>
                            <span><img src={icon3}/></span>
                            <span><img src={icon4}/></span>
                            <span><img src={icon1}/></span>
                            <span><img src={icon2}/></span>
                            <span><img src={icon3}/></span>
                            <span><img src={icon4}/></span>
                            <span><img src={icon1}/></span>
                            <span><img src={icon2}/></span>
                            <span><img src={icon3}/></span>
                            <span><img src={icon4}/></span>
                            <span><img src={icon1}/></span>
                            <span><img src={icon2}/></span>
                        </VerticalSlider>
                        <TableScroll>
                        <table>
                            <tr>
                                <th className='w-10'>Airdrop token 
                                    <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                </th>
                                <th className='w-10'>Airdrop supply <i className="fas fa-question-circle"></i> 
                                    <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                </th>
                                <th colSpan={2} className='w-20'>Claimers groups 
                                    <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                </th>
                                <th className='w-10'>Total claimers <i className="fas fa-question-circle"></i>
                                    <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>

                                </th>
                                <th className='w-10'>Drop progress <i className="fas fa-question-circle"></i> 
                                    <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                </th>
                                <th className='w-10'>Due date <i className="fas fa-question-circle"></i>
                                    <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                </th>
                                <th className='w-10'>Supply balance <i className="fas fa-question-circle"></i>
                                    <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                </th>
                                <th className='w-10 t-center'>Add supply </th>
                            </tr>
                            <tr className='trHighlight'>
                                <td>
                                    <Token><img src={icon1}/> SMART</Token>
                                    <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                </td>
                                <td>100,000,000</td>
                                <td colSpan={2}>
                                    <Token className='lg'>
                                    <img src={icon1}/>
                                    <img src={icon2}/>
                                    <img src={icon3}/>
                                    <img src={icon4}/>
                                    <img src={icon1}/>
                                    <img src={icon2}/>
                                    <img src={icon3}/>
                                    <img src={icon4}/>
                                    <img src={icon1}/>
                                    <img src={icon2}/>
                                    +10
                                    </Token>
                                </td>
                                <td className='color-green'>100.583
                                    <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                </td>
                                <td className='hasBtn'>
                                    <Progress><b style={{width: '20%'}} /></Progress>
                                    <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                </td>
                                <td>April 12, 2024 12:00pm UTC</td>
                                <td className='color-blue hasBtn'>1,000,000,000 
                                    <a className='claim color-red'>WITHDRAWN</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                </td>
                                <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                            </tr>
                            <tr className='trHighlight'>
                                <td>
                                    <Token><img src={icon1}/> SMART</Token>
                                    <a className='arrow-dwn color-green'><i className="fas fa-caret-up"/></a>
                                </td>
                                <td>100,000,000</td>
                                <td colSpan={2}>
                                    <Token className='lg'>
                                    <img src={icon1}/>
                                    <img src={icon2}/>
                                    <img src={icon3}/>
                                    <img src={icon4}/>
                                    <img src={icon1}/>
                                    <img src={icon2}/>
                                    <img src={icon3}/>
                                    <img src={icon4}/>
                                    <img src={icon1}/>
                                    <img src={icon2}/>
                                    +10
                                    </Token>
                                </td>
                                <td className='color-green'>100.583
                                    <a className='arrow-dwn color-green'><i className="fas fa-caret-up"/></a>
                                </td>
                                <td className='hasBtn'>
                                    <Progress><b style={{width: '20%'}} /></Progress>
                                    <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                </td>
                                <td>April 12, 2024 12:00pm UTC</td>
                                <td className='color-blue hasBtn'>1,000,000,000 
                                    <a className='claim color-red'>WITHDRAWN</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                </td>
                                <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                            </tr>

                            <tBody className="tableHighlight">
                                <tr>
                                    <th className='w-10'>Airdrop token </th>
                                    <th className='w-10'>Airdrop supply <i className="fas fa-question-circle"></i> 
                                        <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                    </th>
                                    <th className='w-10'>Claimers groups 
                                        <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                    </th>
                                    <th className='w-10'>Metrics
                                        <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                    </th>
                                    <th className='w-10'>Total claimers <i className="fas fa-question-circle"></i>
                                        <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>

                                    </th>
                                    <th className='w-10'>Drop progress <i className="fas fa-question-circle"></i> 
                                        <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                    </th>
                                    <th className='w-10'>Due date <i className="fas fa-question-circle"></i>
                                        <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                    </th>
                                    <th className='w-10'>Balance <i className="fas fa-question-circle"></i>
                                        <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                    </th>
                                    <th className='w-10 t-center'>Add supply </th>
                                </tr>
                                <tr>
                                    <td>
                                        <img src={Arrow}/> 
                                    </td>
                                    <td>100,000,000</td>
                                    <td >
                                        <Token><img src={icon1}/></Token>
                                    </td>
                                    <td >
                                        <Token className='color-blue'><img src={Metrics}/>  View detail</Token>
                                    </td>
                                    <td className='color-green'>10,058
                                        <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                    </td>
                                    <td className='hasBtn'>
                                        <Progress><b style={{width: '20%'}} /></Progress>
                                        <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                    </td>
                                    <td>April 12, 2024 12:00pm UTC</td>
                                    <td className='color-blue hasBtn'>1,000,000,000 
                                        <a className='claim color-red'>WITHDRAWN</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                    </td>
                                    <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src={Arrow}/> 
                                    </td>
                                    <td>100,000,000</td>
                                    <td >
                                        <Token><img src={icon1}/></Token>
                                    </td>
                                    <td >
                                        <Token className='color-blue'><img src={Metrics}/>  View detail</Token>
                                    </td>
                                    <td className='color-green'>10,058
                                        <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                    </td>
                                    <td className='hasBtn'>
                                        <Progress><b style={{width: '20%'}} /></Progress>
                                        <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                    </td>
                                    <td>April 12, 2024 12:00pm UTC</td>
                                    <td className='color-blue hasBtn'>1,000,000,000 
                                        <a className='claim color-red'>WITHDRAWN</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                    </td>
                                    <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src={Arrow}/> 
                                    </td>
                                    <td>100,000,000</td>
                                    <td >
                                        <Token><img src={icon1}/></Token>
                                    </td>
                                    <td >
                                        <Token className='color-blue'><img src={Metrics}/>  View detail</Token>
                                    </td>
                                    <td className='color-green'>10,058
                                        <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                    </td>
                                    <td className='hasBtn'>
                                        <Progress><b style={{width: '20%'}} /></Progress>
                                        <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                    </td>
                                    <td>April 12, 2024 12:00pm UTC</td>
                                    <td className='color-blue hasBtn'>1,000,000,000 
                                        <a className='claim color-red'>WITHDRAWN</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                    </td>
                                    <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src={Arrow}/> 
                                    </td>
                                    <td>100,000,000</td>
                                    <td >
                                        <Token><img src={icon1}/></Token>
                                    </td>
                                    <td >
                                        <Token className='color-blue'><img src={Metrics}/>  View detail</Token>
                                    </td>
                                    <td className='color-green'>10,058
                                        <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                    </td>
                                    <td className='hasBtn'>
                                        <Progress><b style={{width: '20%'}} /></Progress>
                                        <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                    </td>
                                    <td>April 12, 2024 12:00pm UTC</td>
                                    <td className='color-blue hasBtn'>1,000,000,000 
                                        <a className='claim color-red'>WITHDRAWN</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                    </td>
                                    <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src={Arrow}/> 
                                    </td>
                                    <td>100,000,000</td>
                                    <td >
                                        <Token><img src={icon1}/></Token>
                                    </td>
                                    <td >
                                        <Token className='color-blue'><img src={Metrics}/>  View detail</Token>
                                    </td>
                                    <td className='color-green'>10,058
                                        <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                    </td>
                                    <td className='hasBtn'>
                                        <Progress><b style={{width: '20%'}} /></Progress>
                                        <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                    </td>
                                    <td>April 12, 2024 12:00pm UTC</td>
                                    <td className='color-blue hasBtn'>1,000,000,000 
                                        <a className='claim color-red'>WITHDRAWN</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                    </td>
                                    <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src={Arrow}/> 
                                    </td>
                                    <td>100,000,000</td>
                                    <td >
                                        <Token><img src={icon1}/></Token>
                                    </td>
                                    <td >
                                        <Token className='color-blue'><img src={Metrics}/>  View detail</Token>
                                    </td>
                                    <td className='color-green'>10,058
                                        <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                    </td>
                                    <td className='hasBtn'>
                                        <Progress><b style={{width: '20%'}} /></Progress>
                                        <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                    </td>
                                    <td>April 12, 2024 12:00pm UTC</td>
                                    <td className='color-blue hasBtn'>1,000,000,000 
                                        <a className='claim color-red'>WITHDRAWN</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                    </td>
                                    <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src={Arrow}/> 
                                    </td>
                                    <td>100,000,000</td>
                                    <td >
                                        <Token><img src={icon1}/></Token>
                                    </td>
                                    <td >
                                        <Token className='color-blue'><img src={Metrics}/>  View detail</Token>
                                    </td>
                                    <td className='color-green'>10,058
                                        <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                    </td>
                                    <td className='hasBtn'>
                                        <Progress><b style={{width: '20%'}} /></Progress>
                                        <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                    </td>
                                    <td>April 12, 2024 12:00pm UTC</td>
                                    <td className='color-blue hasBtn'>1,000,000,000 
                                        <a className='claim color-red'>WITHDRAWN</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                    </td>
                                    <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                                </tr>
                            </tBody>

                            <tr>
                                <td>
                                    <Token><img src={icon1}/> SMART</Token>
                                    <a className='arrow-dwn color-green'><i className="fas fa-caret-up"/></a>
                                </td>
                                <td>100,000,000</td>
                                <td colSpan={2}>
                                    <Token className='lg'>
                                    <img src={icon1}/>
                                    <img src={icon2}/>
                                    <img src={icon3}/>
                                    <img src={icon4}/>
                                    <img src={icon1}/>
                                    <img src={icon2}/>
                                    <img src={icon3}/>
                                    <img src={icon4}/>
                                    <img src={icon1}/>
                                    <img src={icon2}/>
                                    +10
                                    </Token>
                                </td>
                                <td className='color-green'>100.583
                                    <a className='arrow-dwn color-green'><i className="fas fa-caret-up"/></a>
                                </td>
                                <td className='hasBtn'>
                                    <Progress><b style={{width: '20%'}} /></Progress>
                                    <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                </td>
                                <td>April 12, 2024 12:00pm UTC</td>
                                <td className='color-blue hasBtn'>1,000,000,000 
                                    <a className='claim color-red'>WITHDRAWN</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                </td>
                                <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                            </tr>
                            <tr>
                                <td>
                                    <Token><img src={icon1}/> SMART</Token>
                                    <a className='arrow-dwn color-green'><i className="fas fa-caret-up"/></a>
                                </td>
                                <td>100,000,000</td>
                                <td colSpan={2}>
                                    <Token className='lg'>
                                    <img src={icon1}/>
                                    <img src={icon2}/>
                                    <img src={icon3}/>
                                    <img src={icon4}/>
                                    <img src={icon1}/>
                                    <img src={icon2}/>
                                    <img src={icon3}/>
                                    <img src={icon4}/>
                                    <img src={icon1}/>
                                    <img src={icon2}/>
                                    +10
                                    </Token>
                                </td>
                                <td className='color-green'>100.583
                                    <a className='arrow-dwn color-green'><i className="fas fa-caret-up"/></a>
                                </td>
                                <td className='hasBtn'>
                                    <Progress><b style={{width: '20%'}} /></Progress>
                                    <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                </td>
                                <td>April 12, 2024 12:00pm UTC</td>
                                <td className='color-blue hasBtn'>1,000,000,000 
                                    <a className='claim color-red'>WITHDRAWN</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                </td>
                                <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                            </tr>
                            <tr>
                                <td>
                                    <Token><img src={icon1}/> SMART</Token>
                                    <a className='arrow-dwn color-green'><i className="fas fa-caret-up"/></a>
                                </td>
                                <td>100,000,000</td>
                                <td colSpan={2}>
                                    <Token className='lg'>
                                    <img src={icon1}/>
                                    <img src={icon2}/>
                                    <img src={icon3}/>
                                    <img src={icon4}/>
                                    <img src={icon1}/>
                                    <img src={icon2}/>
                                    <img src={icon3}/>
                                    <img src={icon4}/>
                                    <img src={icon1}/>
                                    <img src={icon2}/>
                                    +10
                                    </Token>
                                </td>
                                <td className='color-green'>100.583
                                    <a className='arrow-dwn color-green'><i className="fas fa-caret-up"/></a>
                                </td>
                                <td className='hasBtn'>
                                    <Progress><b style={{width: '20%'}} /></Progress>
                                    <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                </td>
                                <td>April 12, 2024 12:00pm UTC</td>
                                <td className='color-blue hasBtn'>1,000,000,000 
                                    <a className='claim color-red'>WITHDRAWN</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                </td>
                                <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                            </tr>
                            <tr>
                                <td>
                                    <Token><img src={icon1}/> SMART</Token>
                                    <a className='arrow-dwn color-green'><i className="fas fa-caret-up"/></a>
                                </td>
                                <td>100,000,000</td>
                                <td colSpan={2}>
                                    <Token className='lg'>
                                    <img src={icon1}/>
                                    <img src={icon2}/>
                                    <img src={icon3}/>
                                    <img src={icon4}/>
                                    <img src={icon1}/>
                                    <img src={icon2}/>
                                    <img src={icon3}/>
                                    <img src={icon4}/>
                                    <img src={icon1}/>
                                    <img src={icon2}/>
                                    +10
                                    </Token>
                                </td>
                                <td className='color-green'>100.583
                                    <a className='arrow-dwn color-green'><i className="fas fa-caret-up"/></a>
                                </td>
                                <td className='hasBtn'>
                                    <Progress><b style={{width: '20%'}} /></Progress>
                                    <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                </td>
                                <td>April 12, 2024 12:00pm UTC</td>
                                <td className='color-blue hasBtn'>1,000,000,000 
                                    <a className='claim color-red'>WITHDRAWN</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                </td>
                                <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                            </tr>
                            <tr>
                                <td className='nobo' colSpan={7}>
                                </td>
                                <td className='nobo'><a onClick={toggleSelectToken} className='btn01 white'>CONNECT WALLET</a></td>
                                <td className='nobo'></td>
                            </tr>
                        </table>
                        </TableScroll>
                    </TableContainer>

                    <TableContainer>
                        <TableTop>
                            <Btn as={Link} to="/airdrop-distribution-launch" className='btn-lg green-border'>Launch a new airdrop distribution</Btn>
                        </TableTop>
                        <VerticalSlider>
                            <div className='VsHead'>Chain</div>
                            <span><img src={icon1} alt="fail"/></span>
                            <span><img src={icon2} alt="fail"/></span>
                            <span><img src={icon3} alt="fail"/></span>
                            <span><img src={icon4} alt="fail"/></span>
                            <span><img src={icon1} alt="fail"/></span>
                            <span><img src={icon2} alt="fail"/></span>
                            <span><img src={icon3} alt="fail"/></span>
                            <span><img src={icon4} alt="fail"/></span>
                            <span><img src={icon1} alt="fail"/></span>
                            <span><img src={icon2} alt="fail"/></span>
                            <span><img src={icon3} alt="fail"/></span>
                            <span><img src={icon4} alt="fail"/></span>
                            <span><img src={icon1} alt="fail"/></span>
                            <span><img src={icon2} alt="fail"/></span>
                        </VerticalSlider>
                        <TableScroll>
                        <table>
                            <tr>
                                <th className='w-10'>Airdrop token 
                                    <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                </th>
                                <th className='w-10'>Airdrop supply <i className="fas fa-question-circle"></i> 
                                    <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                </th>
                                <th className='w-10'>NFT supply 
                                    <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                </th>
                                <th colSpan={2} className='w-20'>Claimers groups 
                                    <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                </th>
                                <th className='w-10'>Total claimers <i className="fas fa-question-circle"></i>
                                    <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>

                                </th>
                                <th className='w-10'>Drop progress <i className="fas fa-question-circle"></i> 
                                    <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                </th>
                                <th className='w-10'>Supply balance <i className="fas fa-question-circle"></i>
                                    <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                </th>
                                <th className='w-10 t-center'>Add supply </th>
                            </tr>
                            <tr className='trHighlight'>
                                <td>
                                    <Token><img src={icon1} alt="fail"/> Bored Ape Yatch...</Token>
                                    <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                </td>
                                <td><Token><img src={icon1} alt="fail"/> Bored Ape Yatch...</Token></td>
                                <td>10,000</td>
                                <td colSpan={2}>
                                    <Token className='lg'>
                                    <img src={icon1} alt="fail"/>
                                    <img src={icon2} alt="fail"/>
                                    <img src={icon3} alt="fail"/>
                                    <img src={icon4} alt="fail"/>
                                    <img src={icon1} alt="fail"/>
                                    <img src={icon2} alt="fail"/>
                                    <img src={icon3} alt="fail"/>
                                    <img src={icon4} alt="fail"/>
                                    <img src={icon1} alt="fail"/>
                                    <img src={icon2} alt="fail"/>
                                    +10
                                    </Token>
                                </td>
                                <td className='color-green'>100.583
                                    <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                </td>
                                <td className='hasBtn'>
                                    <Progress><b style={{width: '20%'}} /></Progress>
                                    <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                </td>
                                <td className='color-blue hasBtn'>1,000,000,000 
                                    <a className='claim color-red'>WITHDRAWN</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                </td>
                                <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                            </tr>
                            <tr className='trHighlight'>
                                <td>
                                    <Token><img src={icon1} alt="fail"/> Bored Ape Yatch...</Token>
                                    <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                </td>
                                <td><Token><img src={icon1} alt="fail"/> Bored Ape Yatch...</Token></td>
                                <td>10,000</td>
                                <td colSpan={2}>
                                    <Token className='lg'>
                                    <img src={icon1} alt="fail"/>
                                    <img src={icon2} alt="fail"/>
                                    <img src={icon3} alt="fail"/>
                                    <img src={icon4} alt="fail"/>
                                    <img src={icon1} alt="fail"/>
                                    <img src={icon2} alt="fail"/>
                                    <img src={icon3} alt="fail"/>
                                    <img src={icon4} alt="fail"/>
                                    <img src={icon1} alt="fail"/>
                                    <img src={icon2} alt="fail"/>
                                    +10
                                    </Token>
                                </td>
                                <td className='color-green'>100.583
                                    <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                </td>
                                <td className='hasBtn'>
                                    <Progress><b style={{width: '20%'}} /></Progress>
                                    <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                </td>
                                <td className='color-blue hasBtn'>1,000,000,000 
                                    <a className='claim color-red'>WITHDRAWN</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                </td>
                                <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                            </tr>

                            <tBody className="tableHighlight">
                                <tr>
                                    <th className='w-10'>Airdrop token </th>
                                    <th className='w-10'>Airdrop supply <i className="fas fa-question-circle"></i> 
                                        <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                    </th>
                                    <th className='w-10'>NFT supply
                                        <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                    </th>
                                    <th className='w-10'>Claimers groups 
                                        <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                    </th>
                                    <th className='w-10'>Metrics
                                        <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                    </th>
                                    <th className='w-10'>Total claimers <i className="fas fa-question-circle"></i>
                                        <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>

                                    </th>
                                    <th className='w-10'>Drop progress <i className="fas fa-question-circle"></i> 
                                        <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                    </th>
                                    <th className='w-10'>Balance <i className="fas fa-question-circle"></i>
                                        <Shorting><a><i className="fas fa-caret-up"></i></a><a><i className="fas fa-caret-down"></i></a></Shorting>
                                    </th>
                                    <th className='w-10 t-center'>Add supply </th>
                                </tr>
                                <tr>
                                    <td>
                                        <img src={Arrow}/> 
                                    </td>
                                    <td>
                                        <img src={Arrow}/> 
                                    </td>
                                    <td>1,000</td>
                                    <td>
                                        <Token><img src={icon1}/></Token>
                                    </td>
                                    <td >
                                        <Token className='color-blue'><img src={Metrics}/>  View detail</Token>
                                    </td>
                                    <td className='color-green'>10,058
                                        <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                    </td>
                                    <td className='hasBtn'>
                                        <Progress><b style={{width: '20%'}} /></Progress>
                                        <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                    </td>
                                    <td className='color-blue hasBtn'>1,000,000,000 
                                        <a className='claim color-red'>WITHDRAWN</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                    </td>
                                    <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src={Arrow}/> 
                                    </td>
                                    <td>
                                        <img src={Arrow}/> 
                                    </td>
                                    <td>1,000</td>
                                    <td>
                                        <Token><img src={icon1}/></Token>
                                    </td>
                                    <td >
                                        <Token className='color-blue'><img src={Metrics}/>  View detail</Token>
                                    </td>
                                    <td className='color-green'>10,058
                                        <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                    </td>
                                    <td className='hasBtn'>
                                        <Progress><b style={{width: '20%'}} /></Progress>
                                        <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                    </td>
                                    <td className='color-blue hasBtn'>1,000,000,000 
                                        <a className='claim color-red'>WITHDRAWN</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                    </td>
                                    <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src={Arrow}/> 
                                    </td>
                                    <td>
                                        <img src={Arrow}/> 
                                    </td>
                                    <td>1,000</td>
                                    <td>
                                        <Token><img src={icon1}/></Token>
                                    </td>
                                    <td >
                                        <Token className='color-blue'><img src={Metrics}/>  View detail</Token>
                                    </td>
                                    <td className='color-green'>10,058
                                        <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                    </td>
                                    <td className='hasBtn'>
                                        <Progress><b style={{width: '20%'}} /></Progress>
                                        <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                    </td>
                                    <td className='color-blue hasBtn'>1,000,000,000 
                                        <a className='claim color-red'>WITHDRAWN</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                    </td>
                                    <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src={Arrow}/> 
                                    </td>
                                    <td>
                                        <img src={Arrow}/> 
                                    </td>
                                    <td>1,000</td>
                                    <td>
                                        <Token><img src={icon1}/></Token>
                                    </td>
                                    <td >
                                        <Token className='color-blue'><img src={Metrics}/>  View detail</Token>
                                    </td>
                                    <td className='color-green'>10,058
                                        <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                    </td>
                                    <td className='hasBtn'>
                                        <Progress><b style={{width: '20%'}} /></Progress>
                                        <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                    </td>
                                    <td className='color-blue hasBtn'>1,000,000,000 
                                        <a className='claim color-red'>WITHDRAWN</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                    </td>
                                    <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src={Arrow}/> 
                                    </td>
                                    <td>
                                        <img src={Arrow}/> 
                                    </td>
                                    <td>1,000</td>
                                    <td>
                                        <Token><img src={icon1}/></Token>
                                    </td>
                                    <td >
                                        <Token className='color-blue'><img src={Metrics}/>  View detail</Token>
                                    </td>
                                    <td className='color-green'>10,058
                                        <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                    </td>
                                    <td className='hasBtn'>
                                        <Progress><b style={{width: '20%'}} /></Progress>
                                        <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                    </td>
                                    <td className='color-blue hasBtn'>1,000,000,000 
                                        <a className='claim color-red'>WITHDRAWN</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                    </td>
                                    <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src={Arrow}/> 
                                    </td>
                                    <td>
                                        <img src={Arrow}/> 
                                    </td>
                                    <td>1,000</td>
                                    <td>
                                        <Token><img src={icon1}/></Token>
                                    </td>
                                    <td >
                                        <Token className='color-blue'><img src={Metrics}/>  View detail</Token>
                                    </td>
                                    <td className='color-green'>10,058
                                        <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                    </td>
                                    <td className='hasBtn'>
                                        <Progress><b style={{width: '20%'}} /></Progress>
                                        <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                    </td>
                                    <td className='color-blue hasBtn'>1,000,000,000 
                                        <a className='claim color-red'>WITHDRAWN</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                    </td>
                                    <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                                </tr>
                            </tBody>

                            <tr className='trHighlight'>
                                <td>
                                    <Token><img src={icon1} alt="fail"/> Bored Ape Yatch...</Token>
                                    <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                </td>
                                <td><Token><img src={icon1} alt="fail"/> Bored Ape Yatch...</Token></td>
                                <td>10,000</td>
                                <td colSpan={2}>
                                    <Token className='lg'>
                                    <img src={icon1} alt="fail"/>
                                    <img src={icon2} alt="fail"/>
                                    <img src={icon3} alt="fail"/>
                                    <img src={icon4} alt="fail"/>
                                    <img src={icon1} alt="fail"/>
                                    <img src={icon2} alt="fail"/>
                                    <img src={icon3} alt="fail"/>
                                    <img src={icon4} alt="fail"/>
                                    <img src={icon1} alt="fail"/>
                                    <img src={icon2} alt="fail"/>
                                    +10
                                    </Token>
                                </td>
                                <td className='color-green'>100.583
                                    <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                </td>
                                <td className='hasBtn'>
                                    <Progress><b style={{width: '20%'}} /></Progress>
                                    <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                </td>
                                <td className='color-blue hasBtn'>1,000,000,000 
                                    <a className='claim color-red'>WITHDRAWN</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                </td>
                                <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                            </tr>
                            <tr className='trHighlight'>
                                <td>
                                    <Token><img src={icon1} alt='fail'/> Bored Ape Yatch...</Token>
                                    <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                </td>
                                <td><Token><img src={icon1} alt='fail'/> Bored Ape Yatch...</Token></td>
                                <td>10,000</td>
                                <td colSpan={2}>
                                    <Token className='lg'>
                                    <img src={icon1} alt='fail'/>
                                    <img src={icon2} alt='fail'/>
                                    <img src={icon3} alt='fail'/>
                                    <img src={icon4} alt='fail'/>
                                    <img src={icon1} alt='fail'/>
                                    <img src={icon2} alt='fail'/>
                                    <img src={icon3} alt='fail'/>
                                    <img src={icon4} alt='fail'/>
                                    <img src={icon1} alt='fail'/>
                                    <img src={icon2} alt='fail'/>
                                    +10
                                    </Token>
                                </td>
                                <td className='color-green'>100.583
                                    <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                </td>
                                <td className='hasBtn'>
                                    <Progress><b style={{width: '20%'}} /></Progress>
                                    <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                </td>
                                <td className='color-blue hasBtn'>1,000,000,000 
                                    <a className='claim color-red'>WITHDRAWN</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                </td>
                                <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                            </tr>
                            <tr className='trHighlight'>
                                <td>
                                    <Token><img src={icon1} alt='fail'/> Bored Ape Yatch...</Token>
                                    <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                </td>
                                <td><Token><img src={icon1} alt='fail'/> Bored Ape Yatch...</Token></td>
                                <td>10,000</td>
                                <td colSpan={2}>
                                    <Token className='lg'>
                                    <img src={icon1} alt='fail'/>
                                    <img src={icon2} alt='fail'/>
                                    <img src={icon3} alt='fail'/>
                                    <img src={icon4} alt='fail'/>
                                    <img src={icon1} alt='fail'/>
                                    <img src={icon2} alt='fail'/>
                                    <img src={icon3} alt='fail'/>
                                    <img src={icon4} alt='fail'/>
                                    <img src={icon1} alt='fail'/>
                                    <img src={icon2} alt='fail'/>
                                    +10
                                    </Token>
                                </td>
                                <td className='color-green'>100.583
                                    <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                </td>
                                <td className='hasBtn'>
                                    <Progress><b style={{width: '20%'}} /></Progress>
                                    <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                </td>
                                <td className='color-blue hasBtn'>1,000,000,000 
                                    <a className='claim color-red'>WITHDRAWN</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                </td>
                                <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                            </tr>
                            <tr className='trHighlight'>
                                <td>
                                    <Token><img src={icon1} alt='fail'/> Bored Ape Yatch...</Token>
                                    <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                </td>
                                <td><Token><img src={icon1} alt='fail'/> Bored Ape Yatch...</Token></td>
                                <td>10,000</td>
                                <td colSpan={2}>
                                    <Token className='lg'>
                                    <img src={icon1} alt='fail'/>
                                    <img src={icon2} alt='fail'/>
                                    <img src={icon3} alt='fail'/>
                                    <img src={icon4} alt='fail'/>
                                    <img src={icon1} alt='fail'/>
                                    <img src={icon2} alt='fail'/>
                                    <img src={icon3} alt='fail'/>
                                    <img src={icon4} alt='fail'/>
                                    <img src={icon1} alt='fail'/>
                                    <img src={icon2} alt='fail'/>
                                    +10
                                    </Token>
                                </td>
                                <td className='color-green'>100.583
                                    <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                </td>
                                <td className='hasBtn'>
                                    <Progress><b style={{width: '20%'}} /></Progress>
                                    <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                </td>
                                <td className='color-blue hasBtn'>1,000,000,000 
                                    <a className='claim color-red'>WITHDRAWN</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                </td>
                                <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                            </tr>
                            <tr className='trHighlight'>
                                <td>
                                    <Token><img src={icon1} alt='fail'/> Bored Ape Yatch...</Token>
                                    <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                </td>
                                <td><Token><img src={icon1} alt='fail'/> Bored Ape Yatch...</Token></td>
                                <td>10,000</td>
                                <td colSpan={2}>
                                    <Token className='lg'>
                                    <img src={icon1} alt='fail'/>
                                    <img src={icon2} alt='fail'/>
                                    <img src={icon3} alt='fail'/>
                                    <img src={icon4} alt='fail'/>
                                    <img src={icon1} alt='fail'/>
                                    <img src={icon2} alt='fail'/>
                                    <img src={icon3} alt='fail'/>
                                    <img src={icon4} alt='fail'/>
                                    <img src={icon1} alt='fail'/>
                                    <img src={icon2} alt='fail'/>
                                    +10
                                    </Token>
                                </td>
                                <td className='color-green'>100.583
                                    <a className='arrow-dwn color-grey'><i className="fas fa-caret-down"/></a>
                                </td>
                                <td className='hasBtn'>
                                    <Progress><b style={{width: '20%'}} /></Progress>
                                    <ProgTxt>23,526,523 / 100,000,000</ProgTxt>
                                </td>
                                <td className='color-blue hasBtn'>1,000,000,000 
                                    <a className='claim color-red'>WITHDRAWN</a><a className='arrow-dwn color-grey'><i className="fas fa-arrow-down"></i></a>
                                </td>
                                <td><a onClick={toggleSelectToken} className='btn01'>ADD SUPPLY</a></td>
                            </tr>
                            
                            <tr>
                                <td className='nobo' colSpan={7}>
                                </td>
                                <td className='nobo'>
                                    <p><span>Total:</span> <span>1,000,000,000,000</span></p>
                                </td>
                                <td className='nobo'></td>
                            </tr>
                        </table>
                        </TableScroll>
                    </TableContainer>
                    </>
                </TabContent>
            </PageContainer>
            {isSelectTokenVisible && <PopupContainer onClose={toggleSelectToken}/>}
            <Footer />
        </>
    )
}

const FlexDiv = styled.div`
    display: flex; align-items: center; justify-content:center; flex-wrap:wrap;
`;
const PageContainer = styled.div `
    padding: 150px 60px 0; text-align: center;
    h1 {font-size: 36px; line-height: 1.4; margin: 0 0 38px 0;
        span {color: #91dc27; text-transform: uppercase;}
    }
    ${Media.lg} {padding: 150px 20px 0; br {display: none;} }
    ${Media.sm} {h1 {font-size: 26px;}}
`
const DropContent = styled.div `
    display: flex; gap: 50px; padding: 0 0 32px 0; text-align: left; 
    h4 {font-size: 21px; font-weight: 700; color: #fff; margin: 0 0 20px 0; line-height: 1.3;}
    p {font-size: 16px; color: #a6a2b0; line-height: 1.7;}
    .DcSec {
        width: 33.33%; display: flex; align-items: flex-start;
        b {border: 4px solid #91dc27; width: 50px; height: 50px; display: flex; align-items: center; justify-content: center; border-radius: 100%; flex-shrink: 0; margin-right: 32px;}
    }
    ${Media.lg} {gap: 30px;
        .DcSec {
            b {width: 40px; height: 40px; border: 2px solid #91dc27; margin-right: 20px;}
        }
    }
    ${Media.md} {
        flex-flow: column;
        .DcSec {
            width: 100%;
        }
    }
`
const DropdownTitle = styled.h2 `
    display: flex; width: 100%; font-size: 24px; font-weight: 700; padding: 36px 0px 38px; align-items: center;
    span {margin-left: auto; margin-right: 6px; transform: scaleY(-1); font-size: 18px;}
    .fa-info-circle {margin-right: 11px; }
    &.open {
        span {transform: scaleY(1);}
    }
`
const TopMenu = styled.div `
    padding: 48px 0px 0px; border-bottom: 2px solid #fff; display: flex; margin-bottom: 118px;
    a {color: #fff; padding: 2px 20px 32px; font-size: 24px; font-weight: bold; flex-grow: 1; text-align: center; position: relative; width: 100%; 
        &:last-child {border-right: 0 solid #000; box-shadow: 0 0 0 #3d3f49; }
        img {margin-right: 11px; filter: brightness(100); transition: all 0.3s ease-in-out 0s; height: 20px;}
        .badge {background-color: #91dc27; font-size: 12px; width: 27px; height: 27px; position: absolute; top: 0; border-radius: 100px; line-height: 27px; margin: -13px 0 0 6px; color: #0d0e13 !important;}
        &.activeRed {color: var(--red2); box-shadow: 0 2px 0 var(--red2);}
        &.active {color: #91dc27; box-shadow: 0 2px 0 #91dc27;
            img {filter: brightness(1);}
        }
        &:hover {color: #545453; img {filter: brightness(1);}}
    }
` 
const TableContainer = styled.div `
    width: 100%; padding-left: 0; position: relative; /* max-height: 475px; */ display: flex; align-items: flex-start; border-top: 1px solid #3b3e4b; padding: 50px 0 0; flex-flow: wrap;
    &.no-padding {padding: 0;}
    &.no-border {border-top: 0; padding-top: 0;}
    table {flex-grow: 1; width: 100%; min-width: 1480px;
        tr {
            th {border: 2px solid #3b3e4b; font-size: 14px; border-top-width: 2px; border-bottom-width: 2px; height: 50px; padding: 0 10px; text-align: left; position: relative; color: #a6a2b0; vertical-align: middle; font-weight: bold; position: sticky;
                .fa-question-circle {font-size: 10px; position: relative; top: -0.65em;}
                &:last-child {
                    border-right: 0px;
                }
                &:first-child {
                    border-left: 0px;
                }
                &.t-center {text-align: center;}
                &.w-10 {width: 10%;}
                &.w-12 {width: 12%;}
                &.w-15 {width: 15%;}
                &.w-20 {width: 20%;}
                &.w-30 {width: 30%;}
                &.w-40 {width: 40%;}
                &.w-50 {width: 50%;}
                &.w-60 {width: 60%;}
                &.w-70 {width: 70%;}
                &.w-80 {width: 80%;}
                &.w-90 {width: 90%;}
                &.w-100 {width: 100%;}
                &.hcol {background: #21232b;}
            }
            td {border: 1px solid #3b3e4b; height: 52px; font-size: 12px; padding: 0 10px; text-align: left; position: relative; vertical-align: middle; color: #a6a2b0; position: relative;
                &:last-child {
                    border-right: 0px;
                }
                &:first-child {
                    border-left: 0px;
                }
                .btn01 {border: 2px solid #000; padding: 2px 10px; font-size: 11px; font-weight: bold; background: none; color: #91dc27; box-shadow: 0 0 15px #323232; display: block; text-align: center; height: 35px; display: flex; align-items: center; justify-content: center; line-height: 1.1;
                    &:hover {background: #91dc27; border: 2px solid #91dc27; box-shadow: 0 0 15px #91dc27; color: #fff;}    
                    &.red {color: #c32b2d;}
                    &.white {color: #fff;
                        &:hover {background: none; box-shadow: none;}
                    }
                }
                .dlt {font-size: 10px; color: #454950; margin: -5px 0 0 0; position: absolute; right: 8px; top: 50%; &:hover {color: #c32b2d;}}
                .claim {color: #a6a2b0; font-weight: 700; opacity: 0; text-align: center; position: absolute; left: 0; right: 0; top: 0; bottom: 0; line-height: 1; display: flex; justify-content: center; align-items: center;
                    &:hover {color: #91dc27;}
                    &.color-white {color: #fff;}
                    &.color-red {color: #c32b2d;}
                    &.color-grey {color: #454950;}
                }
                .arrow-dwn {border-radius: 100px; border: 2px solid transparent; font-size: 12px; text-align: center; padding: 1px 1px 0px 0px; margin: 0 0 0 20px; position: absolute; right: 10px; top: 50%; transform: translateY(-50%); line-height: 1;
                    &:hover {border: 2px solid #c32b2d; color: #c32b2d;}
                    .fa-caret-down {font-size: 14px;}
                }
                &.hcol {background: #21232b;}
                &.hasBtn {
                    span {width: 74px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; display: inline-block;} 
                }
                &:hover {
                    &.hasBtn {color: transparent;}
                    position: relative;
                    .arrow-dwn {opacity: 0;}
                    .claim { opacity: 1;}
                }
                &.nobo {border: 0; vertical-align: top; padding: 20px 8px;
                    &:hover {background: none;}
                    span {width: 100%; justify-content: space-between; margin: 0 0 15px; display: block; color: #a6a2b0;
                        b {display: block; margin-top: 4px;}
                    }
                    p {display: flex; justify-content: space-between;}
                    .becameV {margin-top: 12px; display: inline-block; color: #454950;
                        img {margin-right: 11px;}
                    }
                }
                .react-datepicker-wrapper {
                    input {padding: 0; width: 100%; background: none; border: 0; color: #a6a2b0;}
                }

                .react-date-picker {width: 100%;
                    .react-date-picker__wrapper {border: 0;}
                }
            }
            &.hRow {
                td {
                    background: #1b1c25;
                }
            }
            &:hover {
                td {background: #1b1c25;}
                .nobo {background: none;}
            }
        }
        .tableHighlight {background: #22232d;
            tr {
                td,th {}
            }
        }
        .trHighlight {background: #1b1c25;}
    }
    .color-red {color: #c32b2d}
    .color-green {color: #91dc27}
    .color-blue {color: #2d52f3}
    .color-grey {color: #454950}
    .text-center {text-align: center}
    ${Media.md} {
        table {
            th {}
            td {}
        }
    }
`
const VerticalSlider = styled.div `
    /* height: 470px; */ width: 53px; z-index: 5; padding: 0 0; margin-right: 17px; display: flex; flex-flow: column; /* overflow-y: scroll; */ overflow-x: hidden; flex-shrink: 0; background: #292b33;
    .VsHead {position: sticky; top: 0; border-top: 2px solid #3b3e4b; border-bottom: 2px solid #3b3e4b; height: 52px; display: block; background: #292b33; flex-shrink: 0; color: #a6a2b0; font-weight: bold; font-size: 14px; padding: 16px 0;}
    /* width */
    &::-webkit-scrollbar {width: 5px;}
    /* Track */
    &::-webkit-scrollbar-track {background: transparent; }
    /* Handle */
    &::-webkit-scrollbar-thumb {background: #191b21;}
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover { background: #555;  }
    span {margin: 0px auto; text-align: center; cursor: pointer; width: 53px; height: 52px; display: flex; align-items: center; justify-content: center; flex-shrink: 0; /* border-bottom: 1px solid rgb(59, 62, 75); */
        img {display: inline-block; width: 24px; height: 24px; object-fit: contain; border-radius: 50px;}
        &:hover {
            background: #3b3e4b;
            img {box-shadow: #91dc27 0px 0px 10px; width: 26px; height: 26px; object-fit: contain;}
        }
    }
    .slick-list {}
    .slick-arrow {width: 24px; height: 24px; border-radius: 50px; background: #1b1c25; border: 0; top: 50%; position: absolute; overflow: hidden; color: #a6a2b0; z-index: 5; color: transparent;
        &.slick-prev {left: -22px; top: 13px;
            &:after {content: "\f053"; color: #a6a2b0; position: absolute; left: 0; top: 0; font-family: "Font Awesome 5 Free"; font-weight: 900; left: 6px; top: 4px;}
        }
        &.slick-next {right: -22px; top: 15px;
            &:after {content: "\f054"; color: #a6a2b0; position: absolute; left: 0; top: 0; font-family: "Font Awesome 5 Free"; font-weight: 900; left: 9px; top: 5px;}
        }
    }
    .slick-initialized .slick-slide {height: 50px; display: flex; justify-content: center; align-items: center;}
`
const Token = styled.div `
    display: inline-flex; align-items: center; vertical-align: top; white-space: nowrap; overflow: hidden; max-width: 100px;
    img { margin-right: 10px;}
    &.lg {max-width: inherit; overflow: inherit;
        img {margin-right: 5px;}
    }
`
const TableScroll = styled.div `
    width: 100%; flex-grow: 1; max-width: calc(100% - 70px); overflow-x: auto;
`
const Shorting = styled.div `
    display: flex; position: absolute; right: 10px; flex-flow: column; top: 50%; transform: translateY(-50%);
    a {line-height: 0.7; font-size: 14px;
        .fas {line-height: inherit; }
    }
`
const Progress = styled.div `
    width: 103px; height: 10px; display: flex; justify-content: flex-start; background: #3b3e4b; min-width: 100%;
    b {background: #91dc27;}
`
const ProgTxt = styled.div `
    font-size: 11px; color: #a6a2b0; white-space: nowrap; margin-top: 9px;
`
const TabContent = styled.div `
    
`
const TableTop = styled.div `
    display: flex; justify-content: space-between; align-items: center; width: 100%;
    .searchRight {display: flex; align-items: center;
        .searchVlt {
            border: 2px solid black; background: #21232b; color: #565a69; font-weight: 700; padding: 9px 12px; display: table; height: 40px; width: 400px;
        }        
    }
`
const Btn = styled.button ` 
    border: 2px solid black; background: none; color: #91dc27; margin-left: auto; font-weight: 700; padding: 9px 18px; display: table; margin: 22px 0 19px auto;
    &:hover {background: #91dc27; color:#fff; border-color: #91dc27; box-shadow: 0 0 10px #323232;}
    &.green-border {border: 1px solid #91dc27; }
    &.btn-lg {width: 300px;}
    &.hover-white {
        color:#fff;
        &:hover {background: none; color:#fff; border-color: black; box-shadow: 0 0 10px #323232;}
    }
`
export default AirdropConnected;