/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import Gs from '../theme/globalStyles'
import styled from 'styled-components'
import { Link, NavLink ,useLocation } from 'react-router-dom'
import Media from '../theme/media-breackpoint'
import LogoImg from '../assets/images/logo.png'
import LogoImg2 from '../assets/images/logo2.png'
import LogoImg3 from '../assets/images/logo3.png'
import Icon01 from '../assets/images/eth.png'


function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Footer(props) {

    let query = useQuery();
    const goToAPP = () =>{
        const ref = query.get('ref');
        if(ref !== undefined)
        window.location.href = `https://app.pdo.finance/#/list?ref=${ref}`;
        else
        window.location.href = 'https://app.pdo.finance/#/list';
    }

    return (
        <FooterMain>
            <div className='FMLeft'>
                <p>
                    <span>Powered by: </span>
                    <a href='https://quantum.foundation/' target='_blank'><img src={LogoImg3} /></a>
                </p>
                <span><a href='https://smart.exchange/' target='_blank'><img src={LogoImg2} alt="PDO.finance" /></a></span>
            </div>
            

            <div className="FMRight">
                <p>Supporting : <img src={Icon01} height={19} /> 300+ EVM blockchains</p>
            </div>
        </FooterMain>
    )   
}
const FlexDiv = styled.div`
    display: flex; align-items: center; justify-content:center; flex-wrap:wrap;
`;

const FooterMain = styled(FlexDiv)`
    justify-content: space-between; padding: 18px 43px; background: #16171e; color: #a6a2b0;
    * {display: flex; align-items: center;}
    .FMLeft {
        p {border-right: 1px solid #3b3e4b; padding: 0 20px 0 0; margin-right: 25px; font-size: 12px;
            span {margin-right: 18px; font-weight: 700;}
        }
    }
    .FMRight {font-size: 12px; color: #a6a2b0; font-weight: 700;
        img {margin: 0 6px;}
    }
    ${Media.lg2}{
        padding: 18px 15px;
    }
    ${Media.md2}{
        flex-flow: column;
        .FMRight {margin-top: 20px;}
    }
    ${Media.sm}{
        .FMLeft { justify-content: center; padding-top: 15px;
            p {flex-flow: column;  border-right: 0; justify-content: flex-end;  align-items: flex-start;
                span {margin: -14px 0 5px;}
            }
        }
    }
`;

export default Footer;
