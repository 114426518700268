 

var Media = {} 
Media = {
  xl: '@media(max-width:1600px)',
  lg: '@media(max-width:1440px)',
  lg2: '@media(max-width:1200px)',
  md: '@media(max-width:1024px)',
  md2: '@media(max-width:991px)',
  sm: '@media(max-width:767px)',
  xs: '@media(max-width:500px)',
  uxs: '@media(max-width:400px)' 
}


export default Media; 